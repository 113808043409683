import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    AlertController
} from '@ionic/angular';
import {
    $aio_empty_object
} from '../scripts/interfaces';
import {
    ViewChild
} from '@angular/core';
@Component({
    templateUrl: 'invitedGuests.html',
    selector: 'page-invited-guests',
    styleUrls: ['invitedGuests.css', 'invitedGuests.scss']
})
export class invitedGuests {
    public title: string;
    public guests: any;
    public attending: any;
    public notAttending: any;
    public maybeAttending: any;
    public notResponded: any;
    public showAttending: string;
    public showNot: string;
    public showMaybe: string;
    public showNotResponded: string;
    public token: string;
    public eventId: string;
    public sCount: string;
    public acount: number;
    public ncount: number;
    public mcount: number;
    public rcount: number;
    public acountstring: string;
    public mcountstring: string;
    public ncountstring: string;
    public rcountstring: string;
    public $a: ApperyioHelperService;
    public $v: {
        [name: string]: any
    };
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    @ViewChild('_aio_content') _aio_content;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public alertController: AlertController) {
        this.$a = this.Apperyio;
        this.$v = this.Apperyio.vars;
        this.aioChangeDetector = this.$aio_changeDetector;
    }
    ngOnInit() {
        this.Apperyio.setThinScrollIfNeeded();
    }
    ionViewWillEnter() {
        window.currentScreen = this;
        this.pageIonViewWillEnter__j_1797();
    }
    async pageIonViewWillEnter__j_1797(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.title = localStorage.getItem("guestType");
        this.token = localStorage.getItem("token");
        this.eventId = localStorage.getItem("eventId");
        this.attending = [];
        this.notAttending = [];
        this.maybeAttending = [];
        this.notResponded = [];
        this.showAttending = "hideGrid";
        this.showNot = "hideGrid";
        this.showMaybe = "hideGrid";
        this.showNotResponded = "hideGrid";
        this.Apperyio.execDataService(this, "getEvent3"); // Change service_name to created DataSource name
    }
    async button1Click__j_1822(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Dismiss Modal */
        this.Apperyio.getController("ModalController").dismiss();
    }
    private $aio_dataServices = {
        "getEvent3": "invokeService_getEvent3"
    };
    invokeService_getEvent3(cb?: Function) {
        this.Apperyio.getService("getEvent").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                params = this.$aio_mappingHelper.updateData(params, ['id'], this.$aio_mappingHelper.getSubdata(this.eventId, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'keyboardClose': true,
                        'message': 'please wait...',
                        'showBackdrop': true,
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Mapping */
                        this.guests = this.$aio_mappingHelper.updateData(this.guests, [], this.$aio_mappingHelper.getSubdata(res, ["guests"]));
                        /* Run TypeScript */
                        this.acount = 0;
                        this.ncount = 0;
                        this.mcount = 0;
                        this.rcount = 0;
                        for (let i = 0; i < this.guests.length; i++) {
                            var status = this.guests[i].status;
                            if (status === "Attending") {
                                this.acount = this.acount + 1;
                                this.acountstring = this.acount.toString();
                                this.attending.push(this.guests[i]);
                            } else if (status === "Not Attending") {
                                this.ncount = this.ncount + 1;
                                this.ncountstring = this.ncount.toString();
                                this.notAttending.push(this.guests[i]);
                            } else if (status === "Maybe Attending") {
                                this.mcount = this.mcount + 1;
                                this.mcountstring = this.mcount.toString();
                                this.maybeAttending.push(this.guests[i]);
                            } else if (status === "Not Responded" || status === "Invite Pending") {
                                this.rcount = this.rcount + 1;
                                this.rcountstring = this.rcount.toString();
                                this.notResponded.push(this.guests[i]);
                            } else if (status === "Invite Sent") {
                                this.rcount = this.rcount + 1;
                                this.rcountstring = this.rcount.toString();
                                this.notResponded.push(this.guests[i]);
                            }
                        }
                        if (this.title === "Attending") {
                            this.showAttending = "showGrid";
                            this.showNot = "hideGrid";
                            this.showMaybe = "hideGrid";
                            this.acountstring = this.acount.toString();
                            this.showNotResponded = "hideGrid";
                        } else if (this.title === "Not Attending") {
                            this.showAttending = "hideGrid";
                            this.showNot = "showGrid";
                            this.showMaybe = "hideGrid";
                            this.ncountstring = this.ncount.toString();
                            this.showNotResponded = "hideGrid";
                        } else if (this.title === "Maybe Attending") {
                            this.showAttending = "hideGrid";
                            this.showNot = "hideGrid";
                            this.showMaybe = "showGrid";
                            this.showNotResponded = "hideGrid";
                            this.mcountstring = this.mcount.toString();
                        } else if (this.title === "Not Responded") {
                            this.showAttending = "hideGrid";
                            this.showNot = "hideGrid";
                            this.showMaybe = "hideGrid";
                            this.showNotResponded = "showGrid";
                            this.rcountstring = this.rcount.toString();
                        }
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        const myalert = await this.alertController.create({
                            cssClass: 'my-custom-class',
                            header: 'Error',
                            message: 'There was an error loading your guests. Please try again later.',
                            buttons: ['OK']
                        });
                        await myalert.present();
                    }
                )
            }
        );
    }
}