import {
    NgModule
} from '@angular/core';
import {
    Routes,
    RouterModule
} from '@angular/router';
const routes: Routes = [{
        path: '',
        redirectTo: 'screen1',
        pathMatch: 'full'
    },
    {
        path: 'home',
        loadChildren: () =>
            import ('./Home/Home.module').then(m => m.HomePageModule),
    },
    {
        path: 'profile',
        loadChildren: () =>
            import ('./Profile/Profile.module').then(m => m.ProfilePageModule),
    },
    {
        path: 'tabs',
        loadChildren: () =>
            import ('./Tabs/Tabs.module').then(m => m.TabsPageModule),
    },
    {
        path: 'info',
        loadChildren: () =>
            import ('./Info/Info.module').then(m => m.InfoPageModule),
    },
    {
        path: 'account',
        loadChildren: () =>
            import ('./Account/Account.module').then(m => m.AccountPageModule),
    },
    {
        path: 'screen1',
        loadChildren: () =>
            import ('./Screen1/Screen1.module').then(m => m.Screen1PageModule),
    },
    {
        path: 'update',
        loadChildren: () =>
            import ('./Update/Update.module').then(m => m.UpdatePageModule),
    },
    {
        path: 'block',
        loadChildren: () =>
            import ('./Block/Block.module').then(m => m.BlockPageModule),
    },
    {
        path: 'scenes',
        loadChildren: () =>
            import ('./Scenes/Scenes.module').then(m => m.ScenesPageModule),
    },
    {
        path: 'audio',
        loadChildren: () =>
            import ('./Audio/Audio.module').then(m => m.AudioPageModule),
    },
    {
        path: 'upgrade',
        loadChildren: () =>
            import ('./Upgrade/Upgrade.module').then(m => m.UpgradePageModule),
    },
    {
        path: 'forgot',
        loadChildren: () =>
            import ('./Forgot/Forgot.module').then(m => m.ForgotPageModule),
    },
    {
        path: 'tracker',
        loadChildren: () =>
            import ('./Tracker/Tracker.module').then(m => m.TrackerPageModule),
    },
    {
        path: 'watch',
        loadChildren: () =>
            import ('./Watch/Watch.module').then(m => m.WatchPageModule),
    },
    {
        path: 'photos',
        loadChildren: () =>
            import ('./Photos/Photos.module').then(m => m.PhotosPageModule),
    },
    {
        path: 'intro',
        loadChildren: () =>
            import ('./Intro/Intro.module').then(m => m.IntroPageModule),
    },
    {
        path: 'guests',
        loadChildren: () =>
            import ('./Guests/Guests.module').then(m => m.GuestsPageModule),
    },
    {
        path: 'invite',
        loadChildren: () =>
            import ('./Invite/Invite.module').then(m => m.InvitePageModule),
    },
    {
        path: 'welcome',
        loadChildren: () =>
            import ('./Welcome/Welcome.module').then(m => m.WelcomePageModule),
    },
    {
        path: 'canvas',
        loadChildren: () =>
            import ('./Canvas/Canvas.module').then(m => m.CanvasPageModule),
    },
    {
        path: 'test',
        loadChildren: () =>
            import ('./Test/Test.module').then(m => m.TestPageModule),
    },
    {
        path: 'templates',
        loadChildren: () =>
            import ('./Templates/Templates.module').then(m => m.TemplatesPageModule),
    },
];
@NgModule({
    imports: [RouterModule.forRoot(
        routes, {
            enableTracing: false,
            useHash: true
        }
    )],
    exports: [RouterModule]
})
export class AppRoutingModule {}