import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    $aio_empty_object
} from '../scripts/interfaces';
import {
    ViewChild
} from '@angular/core';
@Component({
    templateUrl: 'Fonts.html',
    selector: 'page-fonts',
    styleUrls: ['Fonts.css', 'Fonts.scss']
})
export class Fonts {
    public fonts: any;
    public recentFonts: any;
    public $a: ApperyioHelperService;
    public $v: {
        [name: string]: any
    };
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    @ViewChild('_aio_content') _aio_content;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    setFont(index): any {
        localStorage.setItem("fontpicked", "yes");
        localStorage.setItem("setfontname", this.fonts[index].name);
        localStorage.setItem("setfontclass", this.fonts[index].class);
        this.Apperyio.getController("ModalController").dismiss(
            /* data */
        );
    }
    setRecentFont(index): any {
        localStorage.setItem("fontpicked", "yes");
        localStorage.setItem("setfontname", this.recentFonts[index].name);
        localStorage.setItem("setfontclass", this.recentFonts[index].class);
        this.Apperyio.getController("ModalController").dismiss(
            /* data */
        );
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef) {
        this.$a = this.Apperyio;
        this.$v = this.Apperyio.vars;
        this.aioChangeDetector = this.$aio_changeDetector;
    }
    ngOnInit() {
        this.Apperyio.setThinScrollIfNeeded();
    }
    ionViewWillEnter() {
        window.currentScreen = this;
        this.pageIonViewWillEnter__j_1431();
    }
    ionViewDidEnter() {
        this.pageIonViewDidEnter__j_1431();
    }
    async pageIonViewWillEnter__j_1431(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Present Loading */
        await (async () => {
            let options = {
                'animated': true,
                'cssClass': 'canvasLoader',
                'duration': 2000,
                'keyboardClose': true,
                'message': 'Loading fonts...',
                'showBackdrop': true,
                'spinner': 'crescent',
            };
            let controller = this.Apperyio.getController('LoadingController');
            const loading = await controller.create(options);
            return await loading.present();
        })();
    }
    async pageIonViewDidEnter__j_1431(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.recentFonts = this.$a.data.getVariable("recentFonts");
        this.fonts = [{
                "name": "Grenze Gotisch",
                "class": "grenze-gotisch"
            },
            {
                "name": "Sue Ellen Francisco",
                "class": "sue-ellen-francisco-regular"
            },
            {
                "name": "Bowlby One SC",
                "class": "bowlby-one-sc-regular"
            },
            {
                "name": "Dawning of a New Day",
                "class": "dawning-of-a-new-day-regular"
            },
            {
                "name": "Contrail One",
                "class": "contrail-one-regular"
            },
            {
                "name": "Rye",
                "class": "rye-regular"
            },
            {
                "name": "NTR",
                "class": "ntr-regular"
            },
            {
                "name": "Averia Serif Libre",
                "class": "averia-serif-libre-regular"
            },
            {
                "name": "Chewy",
                "class": "chewy-regular"
            },
            {
                "name": "Leckerli One",
                "class": "leckerli-one-regular"
            },
            {
                "name": "VT323",
                "class": "vt323-regular"
            },
            {
                "name": "Koulen",
                "class": "koulen-regular"
            },
            {
                "name": "Reenie Beanie",
                "class": "reenie-beanie-regular"
            },
            {
                "name": "Pragati Narrow",
                "class": "pragati-narrow-regular"
            },
            {
                "name": "Rock Salt",
                "class": "rock-salt-regular"
            },
            {
                "name": "Unica One",
                "class": "unica-one-regular"
            },
            {
                "name": "Sofia",
                "class": "sofia-regular"
            },
            {
                "name": "Ropa Sans",
                "class": "ropa-sans-regular"
            },
            {
                "name": "Bad Script",
                "class": "bad-script-regular"
            },
            {
                "name": "Carter One",
                "class": "carter-one-regular"
            },
            {
                "name": "Silkscreen",
                "class": "silkscreen-regular"
            },
            {
                "name": "Bangers",
                "class": "bangers-regular"
            },
            {
                "name": "Playfair Display SC",
                "class": "playfair-display-sc-regular"
            },
            {
                "name": "Staatliches",
                "class": "staatliches-regular"
            },
            {
                "name": "Fugaz One",
                "class": "fugaz-one-regular"
            },
            {
                "name": "Tangerine",
                "class": "tangerine-regular"
            },
            {
                "name": "Literata",
                "class": "literata"
            },
            {
                "name": "Poiret One",
                "class": "poiret-one-regular"
            },
            {
                "name": "Special Elite",
                "class": "special-elite-regular"
            },
            {
                "name": "Gelasio",
                "class": "gelasio-regular"
            },
            {
                "name": "Cookie",
                "class": "cookie-regular"
            },
            {
                "name": "Mate SC",
                "class": "mate-sc-regular"
            },
            {
                "name": "Creepster",
                "class": "creepster-regular"
            },
            {
                "name": "Unbounded",
                "class": "unbounded"
            },
            {
                "name": "Gloria Hallelujah",
                "class": "gloria-hallelujah-regular"
            },
            {
                "name": "Architects Daughter",
                "class": "architects-daughter-regular"
            },
            {
                "name": "Gothic A1",
                "class": "gothic-a1-regular"
            },
            {
                "name": "Luckiest Guy",
                "class": "luckiest-guy-regular"
            },
            {
                "name": "Quattrocento",
                "class": "quattrocento-regular"
            },
            {
                "name": "Alata",
                "class": "alata-regular"
            },
            {
                "name": "Paytone One",
                "class": "paytone-one-regular"
            },
            {
                "name": "Sacramento",
                "class": "sacramento-regular"
            },
            {
                "name": "Alice",
                "class": "alice-regular"
            },
            {
                "name": "Titan One",
                "class": "titan-one-regular"
            },
            {
                "name": "Concert One",
                "class": "concert-one-regular"
            },
            {
                "name": "Patua One",
                "class": "patua-one-regular"
            },
            {
                "name": "Prata",
                "class": "prata-regular"
            },
            {
                "name": "Zeyada",
                "class": "zeyada-regular"
            },
            {
                "name": "Asap Condensed",
                "class": "asap-condensed-regular"
            },
            {
                "name": "Great Vibes",
                "class": "great-vibes-regular"
            },
            {
                "name": "Marcellus",
                "class": "marcellus-regular"
            },
            {
                "name": "Courgette",
                "class": "courgette-regular"
            },
            {
                "name": "Alfa Slab One",
                "class": "alfa-slab-one-regular"
            },
            {
                "name": "ABeeZee",
                "class": "abeezee-regular"
            },
            {
                "name": "Montserrat Alternates",
                "class": "montserrat-alternates-regular"
            },
            {
                "name": "Kalam",
                "class": "kalam-regular"
            },
            {
                "name": "Acme",
                "class": "acme-regular"
            },
            {
                "name": "Passion One",
                "class": "passion-one-regular"
            },
            {
                "name": "Rowdies",
                "class": "rowdies-regular"
            },
            {
                "name": "Urbanist",
                "class": "urbanist"
            },
            {
                "name": "Cinzel",
                "class": "cinzel"
            },
            {
                "name": "Questrial",
                "class": "questrial-regular"
            },
            {
                "name": "Yanone Kaffeesatz",
                "class": "yanone-kaffeesatz"
            },
            {
                "name": "Fjalla One",
                "class": "fjalla-one-regular"
            },
            {
                "name": "Sora",
                "class": "sora"
            },
            {
                "name": "Oxygen",
                "class": "oxygen-regular"
            },
            {
                "name": "Josefin Sans",
                "class": "josefin-sans"
            },
            {
                "name": "Raleway",
                "class": "raleway"
            },
            {
                "name": "Oswald",
                "class": "oswald"
            },
            {
                "name": "Protest Guerrilla",
                "class": "protest-guerrilla-regular"
            },
            {
                "name": "Protest Revolution",
                "class": "protest-revolution-regular"
            },
            {
                "name": "Protest Riot",
                "class": "protest-riot-regular"
            },
            {
                "name": "Fredoka",
                "class": "fredoka"
            },
            {
                "name": "Ysabeau Infant",
                "class": "ysabeau-infant"
            },
            {
                "name": "Xanh Mono",
                "class": "xanh-mono-regular"
            },
            {
                "name": "Yellowtail",
                "class": "yellowtail-regular"
            },
            {
                "name": "Vina Sans",
                "class": "vina-sans-regular"
            },
            {
                "name": "Tilt Prism",
                "class": "tilt-prism"
            },
            {
                "name": "Tilt Neon",
                "class": "tilt-neon"
            },
            {
                "name": "Tilt Warp",
                "class": "tilt-warp"
            },
            {
                "name": "Tektur",
                "class": "tektur"
            },
            {
                "name": "Signika",
                "class": "signika"
            },
            {
                "name": "Shadows Into Light",
                "class": "shadows-into-light-regular"
            },
            {
                "name": "Satisfy",
                "class": "satisfy-regular"
            },
            {
                "name": "Rubik Wet Paint",
                "class": "rubik-wet paint-regular"
            },
            {
                "name": "Rubik Glitch",
                "class": "rubik-glitch-regular"
            },
            {
                "name": "Rubik Pixels",
                "class": "rubik-pixels-regular"
            },
            {
                "name": "Rubik Dirt",
                "class": "rubik-dirt-regular"
            },
            {
                "name": "Rubik Moonrocks",
                "class": "rubik-moonrocks-regular"
            },
            {
                "name": "Rubik Bubbles",
                "class": "rubik-bubbles-regular"
            },
            {
                "name": "Rubik Broken Fax",
                "class": "rubik-broken-fax-regular"
            },
            {
                "name": "Rubik Doodle Triangles",
                "class": "rubik-doodle-triangles-regular"
            },
            {
                "name": "Rubik Lines",
                "class": "rubik-lines-regular"
            },
            {
                "name": "Rubik Scribble",
                "class": "rubik-scribble-regular"
            },
            {
                "name": "Rubik Maps",
                "class": "rubik-maps-regular"
            },
            {
                "name": "Rubik Doodle Shadow",
                "class": "rubik-doodle-shadow-regular"
            },
            {
                "name": "Rubik Mono One",
                "class": "rubik-mono-one-regular"
            },
            {
                "name": "Rubik Glitch Pop",
                "class": "rubik-glitch-pop-regular"
            },
            {
                "name": "Rubik",
                "class": "rubik"
            },
            {
                "name": "Roboto",
                "class": "roboto-regular"
            },
            {
                "name": "Quicksand",
                "class": "quicksand"
            },
            {
                "name": "Preahvihear",
                "class": "preahvihear-regular"
            },
            {
                "name": "Gorditas",
                "class": "gorditas-regular"
            },
            {
                "name": "Homenaje",
                "class": "homenaje-regular"
            },
            {
                "name": "Press Start 2P",
                "class": "press-start-2p-regular"
            },
            {
                "name": "Poppins",
                "class": "poppins-regular"
            },
            {
                "name": "Supermercado One",
                "class": "supermercado-one-regular"
            },
            {
                "name": "Permanent Marker",
                "class": "permanent-marker-regular"
            },
            {
                "name": "Pacifico",
                "class": "pacifico-regular"
            },
            {
                "name": "Outfit",
                "class": "outfit"
            },
            {
                "name": "Open Sans",
                "class": "open-sans"
            },
            {
                "name": "Montserrat",
                "class": "montserrat"
            },
            {
                "name": "Monoton",
                "class": "monoton-regular"
            },
            {
                "name": "Londrina Shadow",
                "class": "londrina-shadow-regular"
            },
            {
                "name": "Londrina Outline",
                "class": "londrina-outline-regular"
            },
            {
                "name": "Londrina Solid",
                "class": "londrina-solid-regular"
            },
            {
                "name": "Lobster",
                "class": "lobster-regular"
            },
            {
                "name": "Lilita One",
                "class": "lilita-one-regular"
            },
            {
                "name": "Lato",
                "class": "lato-regular"
            },
            {
                "name": "Karla",
                "class": "karla"
            },
            {
                "name": "Jost",
                "class": "jost"
            },
            {
                "name": "Indie Flower",
                "class": "indie-flower-regular"
            },
            {
                "name": "Henny Penny",
                "class": "henny-penny-regular"
            },
            {
                "name": "Handjet",
                "class": "handjet"
            },
            {
                "name": "Dosis",
                "class": "dosis"
            },
            {
                "name": "Dancing Script",
                "class": "dancing-script"
            },
            {
                "name": "Crimson Text",
                "class": "crimson-text-regular"
            },
            {
                "name": "Codystar",
                "class": "codystar-regular"
            },
            {
                "name": "Caveat Brush",
                "class": "caveat-brush-regular"
            },
            {
                "name": "Caveat",
                "class": "caveat"
            },
            {
                "name": "Cabin Sketch",
                "class": "cabin-sketch-regular"
            },
            {
                "name": "Cabin",
                "class": "cabin"
            },
            {
                "name": "Bungee Spice",
                "class": "bungee-spice-regular"
            },
            {
                "name": "Bungee Outline",
                "class": "bungee-outline-regular"
            },
            {
                "name": "Bungee Hairline",
                "class": "bungee-hairline-regular"
            },
            {
                "name": "Bungee Shade",
                "class": "bungee-shade-regular"
            },
            {
                "name": "Bungee",
                "class": "bungee-regular"
            },
            {
                "name": "Borel",
                "class": "borel-regular"
            },
            {
                "name": "Bebas Neue",
                "class": "bebas-neue-regular"
            },
            {
                "name": "Archivo Black",
                "class": "archivo-black-regular"
            },
            {
                "name": "Anton",
                "class": "anton-regular"
            },
            {
                "name": "Amatic SC",
                "class": "amatic-sc-regular"
            },
            {
                "name": "Abril Fatface",
                "class": "abril-fatface-regular"
            }
        ];
        this.fonts.sort((a, b) => (a.name > b.name)? 1: (a.name === b.name)?((a.class > b.class)? 1: -1): -1);
    }
    async button1_copy1Click__j_1444(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        localStorage.setItem("fontpicked", "no");
        /* Dismiss Modal */
        this.Apperyio.getController("ModalController").dismiss();
    }
}