import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    NavController
} from '@ionic/angular';
import {
    $aio_empty_object
} from '../scripts/interfaces';
import {
    ViewChild
} from '@angular/core';
@Component({
    templateUrl: 'Sample.html',
    selector: 'page-sample',
    styleUrls: ['Sample.css', 'Sample.scss']
})
export class Sample {
    public currentVideo: string;
    public currentPoster: string;
    public mytheme: string;
    public showPlay: string;
    public showPause: string;
    public $a: ApperyioHelperService;
    public $v: {
        [name: string]: any
    };
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    @ViewChild('_aio_content') _aio_content;
    public mappingData: any = {
        "j_54__disabled": true,
        "j_56__disabled": true,
        "j_58__disabled": true,
        "j_67__disabled": true,
    };
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    playVideo(): any {
        this.showPlay = "hideGrid";
        this.showPause = "showGrid";
        const x = document.getElementById('example') as HTMLVideoElement | null;
        x.play();
        x.addEventListener('ended', (e: any) => {
            this.showPlay = "showGrid";
            this.showPause = "hideGrid";
        });
    }
    pauseVideo(): any {
        this.showPlay = "showGrid";
        this.showPause = "hideGrid";
        const x = document.getElementById('example') as HTMLVideoElement | null;
        x.pause();
    }
    goRecord(): any {
        localStorage.setItem("goaudio", "yes");
        this.Apperyio.getController("ModalController").dismiss(
            /* data */
        );
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public navCtrl: NavController) {
        this.$a = this.Apperyio;
        this.$v = this.Apperyio.vars;
        this.aioChangeDetector = this.$aio_changeDetector;
    }
    ngOnInit() {
        this.Apperyio.setThinScrollIfNeeded();
    }
    ionViewWillEnter() {
        window.currentScreen = this;
        this.pageIonViewWillEnter__j_37();
    }
    async pageIonViewWillEnter__j_37(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.currentVideo = localStorage.getItem("sampleVideo");
        this.currentPoster = localStorage.getItem("samplePoster");
        this.mytheme = localStorage.getItem("sampleTheme");
        this.showPlay = "showGrid";
        this.showPause = "hideGrid";
        const x = document.getElementById('example') as HTMLVideoElement | null;
        x.load();
    }
}