import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    $aio_empty_object
} from '../scripts/interfaces';
import {
    ViewChild
} from '@angular/core';
@Component({
    templateUrl: 'Registry.html',
    selector: 'page-registry',
    styleUrls: ['Registry.css', 'Registry.scss']
})
export class Registry {
    public showAnother: string;
    public registries: any;
    public registryName: string;
    public holder: any;
    public $a: ApperyioHelperService;
    public $v: {
        [name: string]: any
    };
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    @ViewChild('_aio_content') _aio_content;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    doneAdd(): any {
        this.holder[0].registryName = this.registryName;
        this.holder[0].registries = this.registries;
        var nstring = JSON.stringify(this.holder);
        localStorage.setItem("registries", nstring);
        this.Apperyio.getController("ModalController").dismiss(
            /* data */
        );
    }
    addAnother(): any {
        var newObj = {
            "url": "",
            "showTrash": "hideGrid"
        };
        this.registries.push(newObj);
        this.showAnother = "hideGrid";
    }
    urlChange(index): any {
        var regLength = this.registries.length;
        var last = regLength - 1;
        var lastVal = this.registries[last].url;
        if (lastVal === "" || lastVal === undefined) {
            this.showAnother = "hideGrid";
        } else {
            this.showAnother = "showGrid";
        }
        var myval = this.registries[index].url;
        if (myval === "" || myval === undefined) {
            this.registries[index].showTrash = "hideGrid";
        } else {
            this.registries[index].showTrash = "showGrid";
        }
    }
    clearRegistry(index): any {
        var regLength = this.registries.length;
        var last = regLength - 1;
        if (index === 0) {
            this.registries[index].url = "";
        } else if (index === last) {
            this.registries.splice(index, 1);
        } else {
            this.registries[index].url = "";
        }
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef) {
        this.$a = this.Apperyio;
        this.$v = this.Apperyio.vars;
        this.aioChangeDetector = this.$aio_changeDetector;
    }
    ngOnInit() {
        this.Apperyio.setThinScrollIfNeeded();
    }
    ionViewWillEnter() {
        window.currentScreen = this;
        this.pageIonViewWillEnter__j_302();
    }
    async pageIonViewWillEnter__j_302(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        var reg = localStorage.getItem("registries");
        this.holder = []
        this.holder = JSON.parse(reg);
        this.registryName = this.holder[0].registryName;
        this.registries = this.holder[0].registries;
        var regLength = this.registries.length;
        var last = regLength - 1;
        var lastVal = this.registries[last].url;
        if (lastVal === "" || lastVal === undefined) {
            this.showAnother = "hideGrid";
        } else {
            this.showAnother = "showGrid";
        }
        for (let i = 0; i < this.registries.length; i++) {
            var myval = this.registries[i].url;
            if (myval === "" || myval === undefined) {
                this.registries[i].showTrash = "hideGrid";
            } else {
                this.registries[i].showTrash = "showGrid";
            }
        }
    }
    async button1Click__j_321(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Dismiss Modal */
        this.Apperyio.getController("ModalController").dismiss();
    }
}