import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    AlertController
} from '@ionic/angular';
import {
    Dialogs
} from '@ionic-native/dialogs/ngx';
import {
    $aio_empty_object
} from '../scripts/interfaces';
import {
    ViewChild
} from '@angular/core';
@Component({
    templateUrl: 'ImageSelect.html',
    selector: 'page-image-select',
    styleUrls: ['ImageSelect.css', 'ImageSelect.scss']
})
export class ImageSelect {
    public mediaUri: string;
    public mediaBase64: string;
    public images: any;
    public assettype: string;
    public showimage: string;
    public terms: string;
    public showTerms: string;
    public offset: number;
    public showNo: string;
    public placeholder: string;
    public recordCount: string;
    public recordCountNumber: number;
    public showPrev: string;
    public showNext: string;
    public showBar: string;
    public token: string;
    public uid: string;
    public iurl: string;
    public imageToggle: string;
    public imageId: string;
    public showslider: string;
    public tab1text: string;
    public tab2text: string;
    public queryassetstring: string;
    public showSearch: string;
    public pageSize: number;
    public scrollingDisabled: boolean;
    public isOk: boolean;
    public moreImages: any;
    public dragStyle: string;
    public showDefault: string;
    public isPremium: boolean;
    public premiumGif: string;
    public premiumVideo: string;
    public premiumBackground: string;
    public $a: ApperyioHelperService;
    public $v: {
        [name: string]: any
    };
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    @ViewChild('_aio_content') _aio_content;
    public mappingData: any = {
        "j_2317__visible": false,
        "j_2297__visible": false,
    };
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    async pickImage(index): Promise < any > {
        //localStorage.setItem("setImage", "set");
        //    this.Apperyio.data.setVariable("base64", this.images[index].url);
        //      this.Apperyio.getController("ModalController").dismiss(/* data */);
        this.imageId = this.images[index].objectId;

        function dataURItoBlob(dataURI) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);
            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ia], {
                type: mimeString
            });
        }
        localStorage.setItem("setImage", "set");
        if (this.imageToggle === "global") {
            if (this.assettype === "video") {
                this.Apperyio.data.setVariable("videourl", this.images[index].videourl);
                this.Apperyio.data.setVariable("gifurl", this.images[index].gifurl);
                this.Apperyio.getController("ModalController").dismiss(
                    /* data */
                );
            } else if (this.assettype === "background" || this.assettype === "scene") {
                if (this.assettype === "background") {
                    var gurl = this.images[index].gifurl;
                    if (gurl === null || gurl === undefined) {
                    } else if (gurl === "") {
                    } else {
                        gurl = gurl.replace("https://puppet-party.b-cdn.net", "https://puppet-party.b-cdn.net/backgroundgifs");
                    }
                    if (this.images[index].videourl === null || this.images[index].videourl === undefined) {
                        this.Apperyio.data.setVariable("base64", this.images[index].photourl);
                    } else {
                        this.Apperyio.data.setVariable("videourl", this.images[index].videourl);
                        this.Apperyio.data.setVariable("gifurl", gurl);
                        this.Apperyio.data.setVariable("base64", "video");
                    }
                    this.Apperyio.getController("ModalController").dismiss(
                        /* data */
                    );
                } else {
                    if (this.images[index].videourl === null || this.images[index].videourl === undefined) {
                        this.Apperyio.data.setVariable("base64", this.images[index].photourl);
                    } else {
                        this.Apperyio.data.setVariable("videourl", this.images[index].videourl);
                        this.Apperyio.data.setVariable("gifurl", this.images[index].gifurl);
                        this.Apperyio.data.setVariable("base64", "video");
                    }
                    this.Apperyio.getController("ModalController").dismiss(
                        /* data */
                    );
                }
            } else if (this.assettype === "sticker") {
                if (this.images[index].videourl === null || this.images[index].videourl === undefined) {
                    this.Apperyio.data.setVariable("base64", this.images[index].photourl);
                } else {
                    this.Apperyio.data.setVariable("videourl", this.images[index].videourl);
                    this.Apperyio.data.setVariable("gifurl", this.images[index].gifurl);
                    this.Apperyio.data.setVariable("base64", "video");
                }
                this.Apperyio.getController("ModalController").dismiss(
                    /* data */
                );
            } else {
                this.Apperyio.data.setVariable("base64", this.images[index].photourl);
                if (this.images[index].videourl === null || this.images[index].videourl === undefined) {
                    this.Apperyio.data.setVariable("base64", this.images[index].photourl);
                    this.$a.dismissLoading();
                    this.Apperyio.getController("ModalController").dismiss(
                        /* data */
                    );
                } else {
                    this.Apperyio.data.setVariable("videourl", this.images[index].videourl);
                    this.Apperyio.data.setVariable("gifurl", this.images[index].gifurl);
                    this.Apperyio.data.setVariable("base64", "video");
                }
            }
        } else {
            if (this.assettype === "video") {
                this.Apperyio.data.setVariable("videourl", this.images[index].videourl);
                this.Apperyio.data.setVariable("gifurl", this.images[index].gifurl);
                this.Apperyio.getController("ModalController").dismiss(
                    /* data */
                );
            } else if (this.assettype === "background" || this.assettype === "scene") {
                if (this.images[index].videourl === null || this.images[index].videourl === undefined) {
                    this.Apperyio.data.setVariable("base64", this.images[index].photourl);
                } else {
                    this.Apperyio.data.setVariable("videourl", this.images[index].videourl);
                    this.Apperyio.data.setVariable("gifurl", this.images[index].gifurl);
                    this.Apperyio.data.setVariable("base64", "video");
                }
                this.Apperyio.getController("ModalController").dismiss(
                    /* data */
                );
            } else if (this.assettype === "sticker") {
                if (this.images[index].videourl === null || this.images[index].videourl === undefined) {
                    this.Apperyio.data.setVariable("base64", this.images[index].photourl);
                } else {
                    this.Apperyio.data.setVariable("videourl", this.images[index].videourl);
                    this.Apperyio.data.setVariable("gifurl", this.images[index].gifurl);
                    this.Apperyio.data.setVariable("base64", "video");
                }
                this.Apperyio.getController("ModalController").dismiss(
                    /* data */
                );
            } else {
                this.Apperyio.data.setVariable("base64", this.images[index].photourl);
                let prompt = await this.alertController.create({
                    header: '',
                    message: "What would you like to do?",
                    buttons: [{
                            text: 'Insert Image',
                            handler: data => {
                                if (this.images[index].videourl === null || this.images[index].videourl === undefined) {
                                    this.Apperyio.data.setVariable("base64", this.images[index].photourl);
                                    this.Apperyio.getController("ModalController").dismiss(
                                        /* data */
                                    );
                                } else {
                                    this.Apperyio.data.setVariable("videourl", this.images[index].videourl);
                                    this.Apperyio.data.setVariable("gifurl", this.images[index].gifurl);
                                    this.Apperyio.data.setVariable("base64", "video");
                                }
                            }
                        },
                        {
                            text: 'Delete Image',
                            handler: data => {
                                this.deleteImage();
                            }
                        },
                        {
                            text: 'Cancel',
                            handler: data => {
                            }
                        }
                    ]
                });
                await prompt.present();
            }
        }
    }
    doSearch(event): any {
        if (event.keyCode === 13) {
            if (this.imageToggle !== "global") {
                var startActiveTab = (tabs) => {
                    tabs.forEach((tab) => {
                        tab.classList.remove("filter-activeim");
                    });
                    tabs[0].classList.add("filter-activeim");
                };
                var filterTabs1 = document.querySelector(".filter-tabsim");
                var filterButtons1 = document.querySelectorAll(".filter-buttonim");
                var rootfirst1 = document.documentElement;
                rootfirst1.style.setProperty("--translate-filters-slider8", "0%");
                startActiveTab(filterButtons1);
            }
            this.offset = 0;
            this.images = [];
            if (this.showTerms === undefined || this.showTerms === null) {
                this.terms = "any";
                this.Apperyio.execDataService(this, "getImages1"); // Change service_name to created DataSource name
            } else if (this.showTerms === "") {
                this.terms = "any";
                this.Apperyio.execDataService(this, "getImages1"); // Change service_name to created DataSource name
            } else {
                this.terms = this.showTerms;
                this.Apperyio.execDataService(this, "getImages1"); // Change service_name to created DataSource name
            }
        }
    }
    checkScroll(event): any {
        console.log(event);
    }
    goNext(): any {
        this.offset = this.offset + 100;
        if (this.imageToggle === "global") {
            this.$a.execDataService(this, "getImages2"); // Change service_name to created DataSource name
        } else {
            this.$a.execDataService(this, "getMyImages2"); // Change service_name to created DataSource name
        }
    }
    goPrev(): any {
        if (this.offset <= 9) {
            this.offset = 0;
        } else {
            this.offset = this.offset - 9;
        }
        if (this.imageToggle === "global") {
            this.$a.execDataService(this, "getImages1"); // Change service_name to created DataSource name
        } else {
            this.$a.execDataService(this, "getMyImages1"); // Change service_name to created DataSource name
        }
    }
    loadData1($event): any {
        if (this.showNext === "showGridVis") {
            console.log("get more");
            this.isOk = false;
            this.goNext();
            // this.$a.execDataService(this, "getTemplatesSearch2"); // Change service_name to created DataSource name
        } else {
            console.log("no more");
        }
        var findelement = setTimeout(() => {
            $event.target.complete();
        }, 1000);
    }
    dropHandler(ev): any {
        console.log("File(s) dropped");
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            [...ev.dataTransfer.items].forEach((item, i) => {
                // If dropped items aren't files, reject them
                if (item.kind === "file") {
                    const file = item.getAsFile();
                    console.log(`… file[${i}].name = ${file.name}`);
                }
            });
        } else {
            // Use DataTransfer interface to access the file(s)
            [...ev.dataTransfer.files].forEach((file, i) => {
                console.log(`… file[${i}].name = ${file.name}`);
            });
        }
    }
    dragOverHandler(): any {
        this.dragStyle = "background-image: url('https://puppet-party.b-cdn.net/partyweb/draganddropgreen.png');background-size:cover;background-repeat:no-repeat;background-position: center center;";
        console.log("File(s) in drop zone");
        // Prevent default behavior (Prevent file from being opened)
        // ev.preventDefault();
    }
    async deleteImage(): Promise < any > {
        let prompt2 = await this.alertController.create({
            header: 'Are you sure?',
            message: "If this image is used in an invitation or card it will no longer be shown.",
            buttons: [{
                    text: 'Delete Image',
                    handler: data => {
                        this.$a.execDataService(this, "deleteMyImage1"); // Change service_name to created DataSource name
                    }
                },
                {
                    text: 'Cancel',
                    handler: data => {
                    }
                }
            ]
        });
        await prompt2.present();
    }
    pickDefault(): any {
        localStorage.setItem("setImage", "set");
        this.Apperyio.data.setVariable("videourl", this.premiumVideo);
        this.Apperyio.data.setVariable("gifurl", this.premiumGif);
        this.Apperyio.data.setVariable("base64", "video");
        this.Apperyio.getController("ModalController").dismiss(
            /* data */
        );
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public dialogs: Dialogs, public alertController: AlertController) {
        this.$a = this.Apperyio;
        this.$v = this.Apperyio.vars;
        this.aioChangeDetector = this.$aio_changeDetector;
    }
    ngOnInit() {
        this.Apperyio.setThinScrollIfNeeded();
    }
    ionViewWillEnter() {
        window.currentScreen = this;
        this.pageIonViewWillEnter__j_2287();
    }
    async pageIonViewWillEnter__j_2287(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.showDefault = "hideGrid";
        this.dragStyle = "background-image: url('https://puppet-party.b-cdn.net/partyweb/draganddropwhite.png');background-size:cover;background-repeat:no-repeat;background-position: center center;";
        this.pageSize = 100;
        this.scrollingDisabled = false;
        this.assettype = this.Apperyio.data.getVariable("assetType");
        this.uid = localStorage.getItem("uid");
        this.token = localStorage.getItem("token");
        this.imageToggle = "global";
        this.offset = 0;
        this.showPrev = "hideGridVis";
        this.showNext = "hideGridVis";
        this.showBar = "hideGrid";
        this.images = [];
        this.moreImages = [];
        this.recordCount = "0";
        this.recordCountNumber = 0;
        this.showNo = "hideGrid";
        this.placeholder = "Search " + this.assettype + "s";
        this.terms = "any";
        const mainTabs = document.querySelector(".main-tabsim");
        const mainSliderCircle = document.querySelector(".main-slider-circleim");
        const roundButtons = document.querySelectorAll(".round-buttonim");
        const colors = {
            blue: {
                50: {
                    value: "#e3f2fd"
                },
                100: {
                    value: "#bbdefb"
                }
            },
            green: {
                50: {
                    value: "#e8f5e9"
                },
                100: {
                    value: "#c8e6c9"
                }
            },
            purple: {
                50: {
                    value: "#f3e5f5"
                },
                100: {
                    value: "#e1bee7"
                }
            },
            orange: {
                50: {
                    value: "#ffe0b2"
                },
                100: {
                    value: "#ffe0b2"
                }
            },
            red: {
                50: {
                    value: "#ffebee"
                },
                100: {
                    value: "#ffcdd2"
                }
            }
        };
        const getColor = (color, variant) => {
            return colors[color][variant].value;
        };
        const handleActiveTab = (tabs, event, className) => {
            tabs.forEach((tab) => {
                tab.classList.remove(className);
            });
            if (!event.target.classList.contains(className)) {
                event.target.classList.add(className);
            }
        };
        const startActiveTab = (tabs) => {
            tabs.forEach((tab) => {
                tab.classList.remove("filter-activeim");
            });
            tabs[0].classList.add("filter-activeim");
        };
        const filterTabs = document.querySelector(".filter-tabsim");
        const filterButtons = document.querySelectorAll(".filter-buttonim");
        filterTabs.addEventListener("click", (event) => {
            if (event !== null && event.target instanceof HTMLElement) {
                const root = document.documentElement;
                const targetTranslateValue8 = event.target.dataset.translateValue8;
                if (targetTranslateValue8 === "100%") {
                    this.scrollingDisabled = false;
                    this.offset = 0;
                    this.showPrev = "hideGridVis";
                    this.showNext = "hideGridVis";
                    this.showBar = "hideGrid";
                    this.images = [];
                    this.recordCount = "0";
                    this.recordCountNumber = 0;
                    this.showNo = "hideGrid";
                    if (this.assettype === "scene") {
                        this.imageToggle = "global";
                        this.queryassetstring = "scene-image";
                        this.$a.execDataService(this, "getImages1"); // Change service_name to created DataSource name
                    } else if (this.assettype === "background") {
                        this.imageToggle = "global";
                        this.queryassetstring = "background-image";
                        this.$a.execDataService(this, "getImages1"); // Change service_name to created DataSource name
                    } else {
                        this.imageToggle = "mine";
                        this.queryassetstring = this.assettype;
                        this.$a.execDataService(this, "getMyImages1"); // Change service_name to created DataSource name
                    }
                } else if (targetTranslateValue8 === "200%") {
                    this.scrollingDisabled = false;
                    this.offset = 0;
                    this.showPrev = "hideGridVis";
                    this.showNext = "hideGridVis";
                    this.showBar = "hideGrid";
                    this.images = [];
                    this.recordCount = "0";
                    this.recordCountNumber = 0;
                    this.showNo = "hideGrid";
                    if (this.assettype === "scene") {
                        this.imageToggle = "global";
                        this.queryassetstring = "color";
                        this.$a.execDataService(this, "getImages1"); // Change service_name to created DataSource name
                    } else if (this.assettype === "background") {
                        this.imageToggle = "global";
                        this.queryassetstring = "color";
                        this.$a.execDataService(this, "getImages1"); // Change service_name to created DataSource name
                    }
                } else {
                    this.scrollingDisabled = false;
                    this.imageToggle = "global";
                    this.offset = 0;
                    this.showPrev = "hideGridVis";
                    this.showNext = "hideGridVis";
                    this.showBar = "hideGrid";
                    this.images = [];
                    this.recordCount = "0";
                    this.recordCountNumber = 0;
                    this.showNo = "hideGrid";
                    if (this.assettype === "scene") {
                        this.queryassetstring = "scene-video";
                        //this.queryassetstring = "template";
                    } else if (this.assettype === "background") {
                        this.queryassetstring = "background-video";
                    } else {
                        this.queryassetstring = this.assettype;
                    }
                    this.$a.execDataService(this, "getImages1"); // Change service_name to created DataSource name
                }
                if (event.target.classList.contains("filter-buttonim")) {
                    root.style.setProperty("--translate-filters-slider8", targetTranslateValue8);
                    handleActiveTab(filterButtons, event, "filter-activeim");
                }
            }
        });
        const rootfirst = document.documentElement;
        rootfirst.style.setProperty("--translate-filters-slider8", "0%");
        startActiveTab(filterButtons);
        if (this.assettype === "video") {
            this.showimage = "hideGrid";
            this.showslider = "hideGrid";
            this.tab1text = "Images";
            this.tab2text = "My Images";
            this.queryassetstring = "video";
            this.showSearch = "showGrid";
            this.$a.execDataService(this, "getImages1"); // Change service_name to created DataSource name
        } else if (this.assettype === "sticker") {
            this.showimage = "hideGrid";
            this.showslider = "hideGrid";
            this.tab1text = "Images";
            this.tab2text = "My Images";
            this.queryassetstring = "sticker";
            this.showSearch = "showGrid";
            this.$a.execDataService(this, "getImages1"); // Change service_name to created DataSource name
        } else if (this.assettype === "image") {
            this.showimage = "showGrid";
            this.showslider = "hideGrid";
            this.tab1text = "Images";
            this.tab2text = "My Images";
            this.queryassetstring = "image";
            this.showSearch = "hideGrid";
            this.imageToggle = "mine";
            document.getElementById("drop_zone").addEventListener("dragover", async params => {
                params.preventDefault();
                this.dragStyle = "background-image: url('https://puppet-party.b-cdn.net/partyweb/draganddropgreen.png');background-size:cover;background-repeat:no-repeat;background-position: center center;";
            });
            document.getElementById("drop_zone").addEventListener("dragleave", async params => {
                params.preventDefault();
                this.dragStyle = "background-image: url('https://puppet-party.b-cdn.net/partyweb/draganddropwhite.png');background-size:cover;background-repeat:no-repeat;background-position: center center;";
            });
            document.getElementById("drop_zone").addEventListener("drop", async params => {
                params.preventDefault();
                var file = params.dataTransfer.files[0];
                var fileType = file.type;
                var fileSize = file.size;
                var validExtensions = ["image/jpeg", "image/jpg", "image/png"];
                if (fileSize > 5000000) {
                    this.dragStyle = "background-image: url('https://puppet-party.b-cdn.net/partyweb/draganddropwhite.png');background-size:cover;background-repeat:no-repeat;background-position: center center;";
                    const myalert = await this.alertController.create({
                        cssClass: 'my-custom-class',
                        header: 'Alert',
                        message: 'This file is too large. Image file size limit is 5MB.',
                        buttons: ['OK']
                    });
                    await myalert.present();
                } else if (validExtensions.includes(fileType)) {
                    let fileReader = new FileReader();
                    fileReader.onload = () => {
                        var basefile = fileReader.result;

                        function dataURItoBlob(dataURI) {
                            // convert base64/URLEncoded data component to raw binary data held in a string
                            var byteString;
                            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                                byteString = atob(dataURI.split(',')[1]);
                            else
                                byteString = unescape(dataURI.split(',')[1]);
                            // separate out the mime component
                            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
                            // write the bytes of the string to a typed array
                            var ia = new Uint8Array(byteString.length);
                            for (var i = 0; i < byteString.length; i++) {
                                ia[i] = byteString.charCodeAt(i);
                            }
                            return new Blob([ia], {
                                type: mimeString
                            });
                        }
                        localStorage.setItem("setImage", "set");
                        this.Apperyio.data.setVariable("base64", basefile);
                        this.$a.showLoading();
                        var dnow = Date.now();
                        var file1 = dataURItoBlob(basefile);
                        var fname1 = this.uid + "-" + "image" + "-" + dnow;
                        var url = "https://puppetrave.com/api/partyimage.php";
                        if (this.assettype === "scene") {
                            url = "https://puppetrave.com/api/scaleimageparty.php";
                        } else {
                            url = "https://puppetrave.com/api/partyimage.php";
                        }
                        var formData = new FormData();
                        formData.append("thefile", file1);
                        formData.append("name", fname1);
                        const xhr = new XMLHttpRequest();
                        xhr.onload = () => {
                            var obj = JSON.parse(xhr.responseText);
                            var jsss = JSON.stringify(obj);
                            this.iurl = obj.fileURL;
                            this.Apperyio.data.setVariable("base64", this.iurl);
                            this.$a.dismissLoading();
                            this.Apperyio.getController("ModalController").dismiss(
                                /* data */
                            );
                        };
                        xhr.open('POST', url);
                        xhr.send(formData);
                    }
                    var myu = fileReader.readAsDataURL(file);
                } else {
                    this.dragStyle = "background-image: url('https://puppet-party.b-cdn.net/partyweb/draganddropwhite.png');background-size:cover;background-repeat:no-repeat;background-position: center center;";
                    const myalert = await this.alertController.create({
                        cssClass: 'my-custom-class',
                        header: 'Alert',
                        message: 'This is not a supported file type.',
                        buttons: ['OK']
                    });
                    await myalert.present();
                }
            });
            this.$a.execDataService(this, "getMyImages1"); // Change service_name to created DataSource name
        } else if (this.assettype === "scene") {
            this.isPremium = this.Apperyio.data.getVariable("isPremium");
            this.premiumGif = this.Apperyio.data.getVariable("premiumGif");
            this.premiumVideo = this.Apperyio.data.getVariable("premiumVideo");
            this.premiumBackground = this.Apperyio.data.getVariable("premiumBackground");
            if (this.isPremium === true) {
                this.showDefault = "showGrid";
            } else {
                this.showDefault = "hideGrid";
            }
            this.showimage = "hideGrid";
            this.showslider = "showGrid";
            this.tab1text = "Videos";
            this.tab2text = "Images";
            this.queryassetstring = "scene-video";
            //this.queryassetstring = "template";
            this.showSearch = "showGrid";
            this.$a.execDataService(this, "getImages1"); // Change service_name to created DataSource name
        } else if (this.assettype === "background") {
            this.pageSize = 100;
            this.showimage = "hideGrid";
            this.showslider = "showGrid";
            this.tab1text = "Videos";
            this.tab2text = "Images";
            this.queryassetstring = "background-video";
            this.showSearch = "showGrid";
            this.$a.execDataService(this, "getImages1"); // Change service_name to created DataSource name
        } else {
            this.showimage = "showGrid";
            this.showslider = "showGrid";
            this.tab1text = "Images";
            this.tab2text = "My Images";
            this.queryassetstring = "image";
            this.showSearch = "showGrid";
            this.$a.execDataService(this, "getImages1"); // Change service_name to created DataSource name
        }
    }
    async button1_copyClick__j_2316(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Dismiss Modal */
        this.Apperyio.getController("ModalController").dismiss();
    }
    private $aio_dataServices = {
        "CameraService2": "invokeService_CameraService2",
        "PhotoLibrary1": "invokeService_PhotoLibrary1",
        "getImages1": "invokeService_getImages1",
        "VideoLibrary1": "invokeService_VideoLibrary1",
        "getImagesCount1": "invokeService_getImagesCount1",
        "getMyImages1": "invokeService_getMyImages1",
        "getMyImagesCount1": "invokeService_getMyImagesCount1",
        "deleteMyImage1": "invokeService_deleteMyImage1",
        "getImages2": "invokeService_getImages2",
        "getMyImages2": "invokeService_getMyImages2"
    };
    invokeService_CameraService2(cb?: Function) {
        this.Apperyio.getService("CameraService").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let __aio_tmp_val__: any;
                data = this.$aio_mappingHelper.updateData(data, ["destinationType"], 'DATA_URL');
                service.execute({
                    data: data
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Mapping */
                        this.mediaUri = this.$aio_mappingHelper.updateData(this.mediaUri, [], this.$aio_mappingHelper.getSubdata(res, ["imageURI"]));
                        this.mediaBase64 = this.$aio_mappingHelper.updateData(this.mediaBase64, [], ((value) => {
                            return "data:image/jpeg;base64," + value;
                        })(this.$aio_mappingHelper.getSubdata(res, ["imageDataBase64"])));
                        /* Run TypeScript */
                        function dataURItoBlob(dataURI) {
                            // convert base64/URLEncoded data component to raw binary data held in a string
                            var byteString;
                            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                                byteString = atob(dataURI.split(',')[1]);
                            else
                                byteString = unescape(dataURI.split(',')[1]);
                            // separate out the mime component
                            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
                            // write the bytes of the string to a typed array
                            var ia = new Uint8Array(byteString.length);
                            for (var i = 0; i < byteString.length; i++) {
                                ia[i] = byteString.charCodeAt(i);
                            }
                            return new Blob([ia], {
                                type: mimeString
                            });
                        }
                        localStorage.setItem("setImage", "set");
                        this.Apperyio.data.setVariable("base64", this.mediaBase64);
                        this.$a.showLoading();
                        var dnow = Date.now();
                        var file1 = dataURItoBlob(this.mediaBase64);
                        var fname1 = this.uid + "-" + "image" + "-" + dnow;
                        var url = "https://puppetrave.com/api/partyimage.php";
                        if (this.assettype === "scene") {
                            url = "https://puppetrave.com/api/scaleimageparty.php";
                        } else {
                            url = "https://puppetrave.com/api/partyimage.php";
                        }
                        var formData = new FormData();
                        formData.append("thefile", file1);
                        formData.append("name", fname1);
                        const xhr = new XMLHttpRequest();
                        xhr.onload = () => {
                            var obj = JSON.parse(xhr.responseText);
                            var jsss = JSON.stringify(obj);
                            this.iurl = obj.fileURL;
                            this.Apperyio.data.setVariable("base64", this.iurl);
                            this.$a.dismissLoading();
                            this.Apperyio.getController("ModalController").dismiss(
                                /* data */
                            );
                        };
                        xhr.open('POST', url);
                        xhr.send(formData);
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    (err: any) => {
                        console.log(err);
                    }
                )
            }
        );
    }
    invokeService_PhotoLibrary1(cb?: Function) {
        this.Apperyio.getService("CameraService").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let __aio_tmp_val__: any;
                data = this.$aio_mappingHelper.updateData(data, ["sourceType"], 'PHOTOLIBRARY');
                data = this.$aio_mappingHelper.updateData(data, ["destinationType"], 'DATA_URL');
                service.execute({
                    data: data
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Mapping */
                        this.mediaBase64 = this.$aio_mappingHelper.updateData(this.mediaBase64, [], ((value) => {
                            return "data:image/jpeg;base64," + value;
                        })(this.$aio_mappingHelper.getSubdata(res, ["imageDataBase64"])));
                        /* Run TypeScript */
                        function dataURItoBlob(dataURI) {
                            // convert base64/URLEncoded data component to raw binary data held in a string
                            var byteString;
                            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                                byteString = atob(dataURI.split(',')[1]);
                            else
                                byteString = unescape(dataURI.split(',')[1]);
                            // separate out the mime component
                            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
                            // write the bytes of the string to a typed array
                            var ia = new Uint8Array(byteString.length);
                            for (var i = 0; i < byteString.length; i++) {
                                ia[i] = byteString.charCodeAt(i);
                            }
                            return new Blob([ia], {
                                type: mimeString
                            });
                        }
                        localStorage.setItem("setImage", "set");
                        this.Apperyio.data.setVariable("base64", this.mediaBase64);
                        this.$a.showLoading();
                        var dnow = Date.now();
                        var file1 = dataURItoBlob(this.mediaBase64);
                        var fname1 = this.uid + "-" + "image" + "-" + dnow;
                        var url = "https://puppetrave.com/api/partyimage.php";
                        if (this.assettype === "scene") {
                            url = "https://puppetrave.com/api/scaleimageparty.php";
                        } else {
                            url = "https://puppetrave.com/api/partyimage.php";
                        }
                        var formData = new FormData();
                        formData.append("thefile", file1);
                        formData.append("name", fname1);
                        const xhr = new XMLHttpRequest();
                        xhr.onload = () => {
                            var obj = JSON.parse(xhr.responseText);
                            var jsss = JSON.stringify(obj);
                            this.iurl = obj.fileURL;
                            this.Apperyio.data.setVariable("base64", this.iurl);
                            this.$a.dismissLoading();
                            this.Apperyio.getController("ModalController").dismiss(
                                /* data */
                            );
                        };
                        xhr.open('POST', url);
                        xhr.send(formData);
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    (err: any) => {
                        console.log(err);
                    }
                )
            }
        );
    }
    invokeService_getImages1(cb?: Function) {
        this.Apperyio.getService("getImages").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                params = this.$aio_mappingHelper.updateData(params, ['terms'], this.$aio_mappingHelper.getSubdata(this.terms, []));
                params = this.$aio_mappingHelper.updateData(params, ['off'], this.$aio_mappingHelper.getSubdata(this.offset, []));
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                params = this.$aio_mappingHelper.updateData(params, ['type'], this.$aio_mappingHelper.getSubdata(this.queryassetstring, []));
                params = this.$aio_mappingHelper.updateData(params, ['page'], this.$aio_mappingHelper.getSubdata(this.pageSize, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'cssClass': 'canvasLoader',
                        'keyboardClose': true,
                        'message': 'Please wait ...',
                        'mode': 'ios',
                        'showBackdrop': true,
                        'spinner': 'crescent',
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                /* Run TypeScript */
                this.showNo = "hideGrid";
                this.images = [];
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Mapping */
                        this.images = this.$aio_mappingHelper.updateData(this.images, [], this.$aio_mappingHelper.getSubdata(res, []));
                        /* Run TypeScript */
                        if (this.images.length === 0) {
                            this.showNo = "showGrid";
                        } else {
                            if (this.assettype === "background-video" || this.assettype === "scene-video") {
                                for (let i = 0; i < this.images.length; i++) {
                                    this.images[i].visibleUrl = this.images[i].gifurl;
                                }
                            } else {
                                for (let i = 0; i < this.images.length; i++) {
                                    this.images[i].visibleUrl = this.images[i].visibleUrl;
                                }
                            }
                        }
                        /* Invoke data service */
                        this.invokeService_getImagesCount1();
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                    }
                )
            }
        );
    }
    invokeService_VideoLibrary1(cb?: Function) {
        this.Apperyio.getService("CameraService").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let __aio_tmp_val__: any;
                data = this.$aio_mappingHelper.updateData(data, ["mediaType"], 'VIDEO');
                data = this.$aio_mappingHelper.updateData(data, ["destinationType"], 'DATA_URL');
                data = this.$aio_mappingHelper.updateData(data, ["sourceType"], 'PHOTOLIBRARY');
                service.execute({
                    data: data
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        if (cb && typeof cb === "function") cb(res);
                    },
                    (err: any) => {
                        console.log(err);
                    }
                )
            }
        );
    }
    invokeService_getImagesCount1(cb?: Function) {
        this.Apperyio.getService("getImagesCount").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                params = this.$aio_mappingHelper.updateData(params, ['off'], this.$aio_mappingHelper.getSubdata(this.offset, []));
                params = this.$aio_mappingHelper.updateData(params, ['terms'], this.$aio_mappingHelper.getSubdata(this.terms, []));
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                params = this.$aio_mappingHelper.updateData(params, ['type'], this.$aio_mappingHelper.getSubdata(this.queryassetstring, []));
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Run TypeScript */
                        this.recordCountNumber = res;
                        if (this.recordCountNumber > 100) {
                            if (this.offset > 0) {
                                this.showPrev = "showGridVis";
                                if (this.offset + 100 >= this.recordCountNumber) {
                                    this.showNext = "hideGridVis";
                                } else {
                                    this.showNext = "showGridVis";
                                }
                            } else {
                                this.showPrev = "hideGridVis";
                                this.showNext = "showGridVis";
                            }
                        } else {
                            this.showNext = "hideGridVis";
                            this.showPrev = "hideGridVis";
                            this.showBar = "showGrid";
                        }
                        this.showBar = "showGrid";
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                    }
                )
            }
        );
    }
    invokeService_getMyImages1(cb?: Function) {
        this.Apperyio.getService("getMyImages").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                params = this.$aio_mappingHelper.updateData(params, ['off'], this.$aio_mappingHelper.getSubdata(this.offset, []));
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                params = this.$aio_mappingHelper.updateData(params, ['page'], this.$aio_mappingHelper.getSubdata(this.pageSize, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'cssClass': 'canvasLoader',
                        'keyboardClose': true,
                        'message': 'Please wait ...',
                        'mode': 'ios',
                        'showBackdrop': true,
                        'spinner': 'crescent',
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                /* Run TypeScript */
                this.showNo = "hideGrid";
                this.images = [];
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Mapping */
                        this.images = this.$aio_mappingHelper.updateData(this.images, [], this.$aio_mappingHelper.getSubdata(res, []));
                        /* Run TypeScript */
                        if (this.images.length === 0) {
                            this.showNo = "showGrid";
                        } else {
                            for (let i = 0; i < this.images.length; i++) {
                                this.images[i].visibleUrl = this.images[i].photourl;
                            }
                        }
                        /* Invoke data service */
                        this.invokeService_getMyImagesCount1();
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    (err: any) => {
                        console.log(err);
                    }
                )
            }
        );
    }
    invokeService_getMyImagesCount1(cb?: Function) {
        this.Apperyio.getService("getMyImagesCount").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                params = this.$aio_mappingHelper.updateData(params, ['off'], this.$aio_mappingHelper.getSubdata(this.offset, []));
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Run TypeScript */
                        this.recordCountNumber = res;
                        if (this.recordCountNumber > 100) {
                            if (this.offset > 0) {
                                this.showPrev = "showGridVis";
                                if (this.offset + 100 >= this.recordCountNumber) {
                                    this.showNext = "hideGridVis";
                                } else {
                                    this.showNext = "showGridVis";
                                }
                            } else {
                                this.showPrev = "hideGridVis";
                                this.showNext = "showGridVis";
                            }
                        } else {
                            this.showNext = "hideGridVis";
                            this.showPrev = "hideGridVis";
                            this.showBar = "showGrid";
                        }
                        this.showBar = "showGrid";
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                    }
                )
            }
        );
    }
    invokeService_deleteMyImage1(cb?: Function) {
        this.Apperyio.getService("deleteMyImage").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                params = this.$aio_mappingHelper.updateData(params, ['id'], this.$aio_mappingHelper.getSubdata(this.imageId, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'cssClass': 'canvasLoader',
                        'keyboardClose': true,
                        'message': 'Deleting image...',
                        'mode': 'ios',
                        'showBackdrop': true,
                        'spinner': 'crescent',
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        this.imageToggle = "mine";
                        this.offset = 0;
                        this.showPrev = "hideGridVis";
                        this.showNext = "hideGridVis";
                        this.showBar = "hideGrid";
                        this.images = [];
                        this.recordCount = "0";
                        this.recordCountNumber = 0;
                        this.showNo = "hideGrid";
                        /* Invoke data service */
                        this.invokeService_getMyImages1();
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        const myalert = await this.alertController.create({
                            cssClass: 'my-custom-class',
                            header: 'Alert',
                            message: 'There was an error deleting your image. Please try again later.',
                            buttons: ['OK']
                        });
                        await myalert.present();
                    }
                )
            }
        );
    }
    invokeService_getImages2(cb?: Function) {
        this.Apperyio.getService("getImages").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                params = this.$aio_mappingHelper.updateData(params, ['type'], this.$aio_mappingHelper.getSubdata(this.queryassetstring, []));
                params = this.$aio_mappingHelper.updateData(params, ['off'], this.$aio_mappingHelper.getSubdata(this.offset, []));
                params = this.$aio_mappingHelper.updateData(params, ['page'], this.$aio_mappingHelper.getSubdata(this.pageSize, []));
                params = this.$aio_mappingHelper.updateData(params, ['terms'], this.$aio_mappingHelper.getSubdata(this.terms, []));
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Mapping */
                        this.moreImages = this.$aio_mappingHelper.updateData(this.moreImages, [], this.$aio_mappingHelper.getSubdata(res, []));
                        /* Run TypeScript */
                        if (this.offset + 100 >= this.recordCountNumber) {
                            this.scrollingDisabled = true;
                            this.showNext = "hideGridVis";
                        } else {
                            this.scrollingDisabled = false;
                            this.showNext = "showGridVis";
                        }
                        this.images = this.images.concat(this.moreImages);
                        var setok = setTimeout(() => {
                            this.isOk = true;
                        }, 1500);
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    (err: any) => {
                        console.log(err);
                    }
                )
            }
        );
    }
    invokeService_getMyImages2(cb?: Function) {
        this.Apperyio.getService("getMyImages").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                params = this.$aio_mappingHelper.updateData(params, ['off'], this.$aio_mappingHelper.getSubdata(this.offset, []));
                params = this.$aio_mappingHelper.updateData(params, ['page'], this.$aio_mappingHelper.getSubdata(this.pageSize, []));
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Mapping */
                        this.moreImages = this.$aio_mappingHelper.updateData(this.moreImages, [], this.$aio_mappingHelper.getSubdata(res, []));
                        /* Run TypeScript */
                        if (this.offset + 100 >= this.recordCountNumber) {
                            this.scrollingDisabled = true;
                            this.showNext = "hideGridVis";
                        } else {
                            this.scrollingDisabled = false;
                            this.showNext = "showGridVis";
                        }
                        this.images = this.images.concat(this.moreImages);
                        var setok = setTimeout(() => {
                            this.isOk = true;
                        }, 1500);
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    (err: any) => {
                        console.log(err);
                    }
                )
            }
        );
    }
}