import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    Dialogs
} from '@ionic-native/dialogs/ngx';
import {
    $aio_empty_object
} from '../scripts/interfaces';
import {
    ViewChild
} from '@angular/core';
@Component({
    templateUrl: 'Crop.html',
    selector: 'page-crop',
    styleUrls: ['Crop.css', 'Crop.scss']
})
export class Crop {
    public window: any;
    public mediaBase64: string;
    public uid: string;
    public iurl: string;
    public token: string;
    public assettype: string;
    public $a: ApperyioHelperService;
    public $v: {
        [name: string]: any
    };
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    @ViewChild('_aio_content') _aio_content;
    public mappingData: any = {
        "j_1478__visible": false,
    };
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    loadImage(): any {
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public dialogs: Dialogs) {
        this.$a = this.Apperyio;
        this.$v = this.Apperyio.vars;
        this.window = window;
        this.aioChangeDetector = this.$aio_changeDetector;
    }
    ngOnInit() {
        this.Apperyio.setThinScrollIfNeeded();
    }
    ionViewWillEnter() {
        window.currentScreen = this;
        this.pageIonViewWillEnter__j_1473();
    }
    ionViewDidEnter() {
        this.pageIonViewDidEnter__j_1473();
    }
    async pageIonViewWillEnter__j_1473(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.mediaBase64 = this.Apperyio.data.getVariable("base64");
        this.assettype = this.Apperyio.data.getVariable("assetType");
        this.uid = localStorage.getItem("uid");
        this.token = localStorage.getItem("token");
        /* Present Loading */
        await (async () => {
            let options = {
                'animated': true,
                'duration': 4000,
                'keyboardClose': true,
                'message': 'loading image...',
                'showBackdrop': true,
                'spinner': 'crescent',
                'translucent': false,
            };
            let controller = this.Apperyio.getController('LoadingController');
            const loading = await controller.create(options);
            return await loading.present();
        })();
    }
    async pageIonViewDidEnter__j_1473(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        var checkstyle1 = document.getElementById("circlestyle");
        localStorage.setItem("cropshape", "rectangle");
        var stylestring1 = JSON.stringify(checkstyle1);
        if (stylestring1.indexOf('null') !== -1) {} else {
            document.getElementById("circlestyle").remove();
        }
        const mainTabs1 = document.querySelector(".main-tabsm");
        const mainSliderCircle1 = document.querySelector(".main-slider-circlem");
        const roundButtons1 = document.querySelectorAll(".round-buttonm");
        var currenttab = "";
        const colors1 = {
            blue: {
                50: {
                    value: "#e3f2fd"
                },
                100: {
                    value: "#bbdefb"
                }
            },
            green: {
                50: {
                    value: "#e8f5e9"
                },
                100: {
                    value: "#c8e6c9"
                }
            },
            purple: {
                50: {
                    value: "#f3e5f5"
                },
                100: {
                    value: "#e1bee7"
                }
            },
            orange: {
                50: {
                    value: "#ffe0b2"
                },
                100: {
                    value: "#ffe0b2"
                }
            },
            red: {
                50: {
                    value: "#ffebee"
                },
                100: {
                    value: "#ffcdd2"
                }
            }
        };
        const getColor1 = (color, variant) => {
            return colors1[color][variant].value;
        };
        const handleActiveTab1 = (tabs, event, className) => {
            tabs.forEach((tab) => {
                tab.classList.remove(className);
            });
            if (!event.target.classList.contains(className)) {
                event.target.classList.add(className);
            }
        };
        const startActiveTab1 = (tabs) => {
            tabs.forEach((tab) => {
                tab.classList.remove("filter-activem");
            });
            tabs[0].classList.add("filter-activem");
        };
        const filterTabs1 = document.querySelector(".filter-tabsm");
        const filterButtons1 = document.querySelectorAll(".filter-buttonm");
        filterTabs1.addEventListener("click", (event) => {
            if (event !== null && event.target instanceof HTMLElement) {
                const root = document.documentElement;
                const targetTranslateValue1 = event.target.dataset.translateValue1;
                if (targetTranslateValue1 === "100%") {
                    var checkstyle = document.getElementById("circlestyle");
                    var stylestring = JSON.stringify(checkstyle);
                    if (stylestring.indexOf('null') !== -1) {
                        var style = document.createElement('style');
                        style.setAttribute("id", "circlestyle");
                        style.innerHTML = '.cropper-crop-box, .cropper-view-box {border-radius: 50%;}.cropper-view-box {box-shadow: 0 0 0 1px #39f;outline: 0;}.cropper-face {background-color:inherit !important;}.cropper-dashed, .cropper-point.point-se, .cropper-point.point-sw, .cropper-point.point-nw,   .cropper-point.point-ne, .cropper-line {display:none !important;}.cropper-view-box {outline:inherit !important;}';
                        localStorage.setItem("cropshape", "circle");
                        document.head.appendChild(style);
                    } else {
                    }
                } else {
                    localStorage.setItem("cropshape", "rectangle");
                    var checkstyle = document.getElementById("circlestyle");
                    var stylestring = JSON.stringify(checkstyle);
                    if (stylestring.indexOf('null') !== -1) {} else {
                        document.getElementById("circlestyle").remove();
                        localStorage.setItem("cropshape", "rectangle");
                    }
                }
                if (event.target.classList.contains("filter-buttonm")) {
                    root.style.setProperty("--translate-filters-slider1", targetTranslateValue1);
                    handleActiveTab1(filterButtons1, event, "filter-activem");
                }
            }
        });
        const rootfirst1 = document.documentElement;
        rootfirst1.style.setProperty("--translate-filters-slider1", "0");
        startActiveTab1(filterButtons1);
        /* Run TypeScript */
        function dataURItoBlob(dataURI) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);
            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ia], {
                type: mimeString
            });
        }
        // import 'cropperjs/dist/cropper.css';
        var image = document.getElementById('cropimage');
        var cropper = new this.window.Cropper(image, {
            //aspectRatio: 16 / 9,
            aspectRatio: NaN,
            autoCropArea: 1,
            crop(event) {
            },
        });

        function getRoundedCanvas(sourceCanvas) {
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var width = sourceCanvas.width;
            var height = sourceCanvas.height;
            canvas.width = width;
            canvas.height = height;
            context.imageSmoothingEnabled = true;
            context.drawImage(sourceCanvas, 0, 0, width, height);
            context.globalCompositeOperation = 'destination-in';
            context.beginPath();
            context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
            context.fill();
            return canvas;
        }

        function getOvalCanvas(sourceCanvas) {
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var offsetTop = Math.round(cropper.getCropBoxData().top);
            var offsetLeft = Math.round(cropper.getCropBoxData().left);
            var width = sourceCanvas.width;
            var height = sourceCanvas.height;
            canvas.width = width;
            canvas.height = height;
            context.imageSmoothingEnabled = true;
            context.drawImage(sourceCanvas, 0, 0, width, height);
            context.globalCompositeOperation = 'destination-in';
            context.beginPath();
            context.ellipse(width / 2, height / 2, width / 2, height / 2, 0 * Math.PI, 0, 45 * Math.PI);
            context.fill();
            return canvas;
        }
        //document.getElementById("cropit").addEventListener("click", cropimage);
        document.getElementById("cropit").addEventListener("click", params => {
            // cropper.getCroppedCanvas({ maxWidth: 4096, maxHeight: 4096 })
            var croptype = localStorage.getItem("cropshape");
            if (croptype === "circle") {
                // var croppedimage = cropper.getCroppedCanvas().toDataURL("image/png");
                var croppedimage = cropper.getCroppedCanvas();
                var roundedCanvas = getOvalCanvas(croppedimage).toDataURL("image/png");
                //  this.Apperyio.data.setVariable("base64", roundedCanvas);
                localStorage.setItem("setImage", "set");
                this.$a.showLoading();
                var dnow = Date.now();
                var file1 = dataURItoBlob(roundedCanvas);
                var fname1 = this.uid + "-" + "image" + "-" + dnow;
                var url = "https://puppetrave.com/api/partyimage.php";
                if (this.assettype === "scene") {
                    url = "https://puppetrave.com/api/scaleimageparty.php";
                } else {
                    url = "https://puppetrave.com/api/partyimage.php";
                }
                var formData = new FormData();
                formData.append("thefile", file1);
                formData.append("name", fname1);
                var xhr = new XMLHttpRequest();
                xhr.onload = () => {
                    var obj = JSON.parse(xhr.responseText);
                    var jsss = JSON.stringify(obj);
                    this.iurl = obj.fileURL;
                    this.Apperyio.data.setVariable("base64", this.iurl);
                    this.$a.dismissLoading();
                    this.Apperyio.getController("ModalController").dismiss(
                        /* data */
                    );
                };
                xhr.open('POST', url);
                xhr.send(formData);
            } else {
                var croppedimage = cropper.getCroppedCanvas().toDataURL("image/png");
                localStorage.setItem("setImage", "set");
                this.$a.showLoading();
                var dnow = Date.now();
                var file1 = dataURItoBlob(croppedimage);
                var fname1 = this.uid + "-" + "image" + "-" + dnow;
                var url = "https://puppetrave.com/api/partyimage.php";
                if (this.assettype === "scene") {
                    url = "https://puppetrave.com/api/scaleimageparty.php";
                } else {
                    url = "https://puppetrave.com/api/partyimage.php";
                }
                var formData = new FormData();
                formData.append("thefile", file1);
                formData.append("name", fname1);
                var xhr = new XMLHttpRequest();
                xhr.onload = () => {
                    var obj = JSON.parse(xhr.responseText);
                    var jsss = JSON.stringify(obj);
                    this.iurl = obj.fileURL;
                    this.Apperyio.data.setVariable("base64", this.iurl);
                    this.$a.dismissLoading();
                    this.Apperyio.getController("ModalController").dismiss(
                        /* data */
                    );
                };
                xhr.open('POST', url);
                xhr.send(formData);
            }
        });

        function cropimage() {
        }
        const mainTabs = document.querySelector(".main-tabsu");
        const mainSliderCircle = document.querySelector(".main-slider-circleu");
        const roundButtons = document.querySelectorAll(".round-buttonu");
        const colors = {
            blue: {
                50: {
                    value: "#e3f2fd"
                },
                100: {
                    value: "#bbdefb"
                }
            },
            green: {
                50: {
                    value: "#e8f5e9"
                },
                100: {
                    value: "#c8e6c9"
                }
            },
            purple: {
                50: {
                    value: "#f3e5f5"
                },
                100: {
                    value: "#e1bee7"
                }
            },
            orange: {
                50: {
                    value: "#ffe0b2"
                },
                100: {
                    value: "#ffe0b2"
                }
            },
            red: {
                50: {
                    value: "#ffebee"
                },
                100: {
                    value: "#ffcdd2"
                }
            }
        };
        const getColor = (color, variant) => {
            return colors[color][variant].value;
        };
        const handleActiveTab = (tabs, event, className) => {
            tabs.forEach((tab) => {
                tab.classList.remove(className);
            });
            if (!event.target.classList.contains(className)) {
                event.target.classList.add(className);
            }
        };
        const startActiveTab = (tabs) => {
            tabs.forEach((tab) => {
                tab.classList.remove("filter-activeu");
            });
            tabs[0].classList.add("filter-activeu");
        };
        const filterTabs = document.querySelector(".filter-tabsu");
        const filterButtons = document.querySelectorAll(".filter-buttonu");
        filterTabs.addEventListener("click", (event) => {
            if (event !== null && event.target instanceof HTMLElement) {
                const root = document.documentElement;
                const targetTranslateValue3 = event.target.dataset.translateValue3;
                if (targetTranslateValue3 === "100%") {
                    cropper.setAspectRatio(16 / 9);
                } else if (targetTranslateValue3 === "200%") {
                    cropper.setAspectRatio(4 / 3);
                } else if (targetTranslateValue3 === "300%") {
                    cropper.setAspectRatio(1 / 1);
                } else if (targetTranslateValue3 === "400%") {
                    cropper.setAspectRatio(2 / 3);
                } else {
                    cropper.setAspectRatio(NaN);
                }
                if (event.target.classList.contains("filter-buttonu")) {
                    root.style.setProperty("--translate-filters-slider3", targetTranslateValue3);
                    handleActiveTab(filterButtons, event, "filter-activeu");
                }
            }
        });
        const rootfirst = document.documentElement;
        rootfirst.style.setProperty("--translate-filters-slider3", "0");
        startActiveTab(filterButtons);
        /* Run TypeScript */
        function dataURItoBlob2(dataURI) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);
            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ia], {
                type: mimeString
            });
        }
        var imageplain = document.getElementById('cropimageplain');
        var cropperplain = new this.window.Cropper(imageplain, {
            //aspectRatio: 16 / 9,
            aspectRatio: NaN,
            autoCropArea: 1,
            crop(event) {
            },
        });
        document.getElementById("cancel").addEventListener("click", async params => {
            //var croppedimage = cropper.getCroppedCanvas().toDataURL("image/png");
            if (this.mediaBase64.indexOf('https://backendlessappcontent.com') !== -1) {
                this.Apperyio.data.setVariable("base64", this.mediaBase64);
                this.Apperyio.getController("ModalController").dismiss(
                    /* data */
                );
            } else {
                var croppedimageplain = cropperplain.getCroppedCanvas().toDataURL("image/png");
                localStorage.setItem("setImage", "set");
                this.$a.showLoading();
                var dnow = Date.now();
                var file1 = dataURItoBlob2(croppedimageplain);
                var fname1 = this.uid + "-" + "image" + "-" + dnow;
                var url = "https://puppetrave.com/api/partyimage.php";
                if (this.assettype === "scene") {
                    url = "https://puppetrave.com/api/scaleimageparty.php";
                } else {
                    url = "https://puppetrave.com/api/partyimage.php";
                }
                var formData = new FormData();
                formData.append("thefile", file1);
                formData.append("name", fname1);
                var xhr = new XMLHttpRequest();
                xhr.onload = () => {
                    var obj = JSON.parse(xhr.responseText);
                    var jsss = JSON.stringify(obj);
                    this.iurl = obj.fileURL;
                    this.Apperyio.data.setVariable("base64", this.iurl);
                    this.$a.dismissLoading();
                    this.Apperyio.getController("ModalController").dismiss(
                        /* data */
                    );
                };
                xhr.open('POST', url);
                xhr.send(formData);
            }
        });
    }
}