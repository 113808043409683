import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    AlertController
} from '@ionic/angular';
import {
    $aio_empty_object
} from '../scripts/interfaces';
import {
    ViewChild
} from '@angular/core';
@Component({
    templateUrl: 'Search.html',
    selector: 'page-search',
    styleUrls: ['Search.css', 'Search.scss']
})
export class Search {
    public searchTerms: string;
    public queryCheck: string;
    public catName: string;
    public showTitle: string;
    public showSearch: string;
    public token: string;
    public templates: any;
    public showNo: string;
    public count: number;
    public recordCountNumber: number;
    public showPrev: string;
    public showNext: string;
    public showBar: string;
    public offset: number;
    public moreTemplates: any;
    public isOk: boolean;
    public numTimesLeft: number;
    public scrollingDisabled: boolean;
    public $a: ApperyioHelperService;
    public $v: {
        [name: string]: any
    };
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    @ViewChild('_aio_content') _aio_content;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    goPreview(index): any {
        var largeurl = this.templates[index].invitation.canvasFrontImage;
        largeurl = largeurl.replace("small", "large");
        var iobject = {
            "templateId": this.templates[index].objectId,
            "hasBack": this.templates[index].invitation.hasBack,
            "templateName": this.templates[index].title,
            "templateCost": this.templates[index].cost,
            "templateCategory": this.templates[index].category,
            "templateBackground": this.templates[index].invitation.mainBackgroundUrl,
            "templateFrontBackground": this.templates[index].invitation.cardFrontBackground,
            "templateBackBackground": this.templates[index].invitation.cardBackBackground,
            "templateFrontImage": this.templates[index].invitation.canvasFrontImage,
            "templateBackImage": this.templates[index].invitation.canvasBackImage,
            "envelopeClass": this.templates[index].invitation.envelopeColor,
            "envelopeLiner": this.templates[index].invitation.linerColor,
            "templatePuppetFrontPosition": this.templates[index].invitation.puppetFrontPosition,
            "templatePuppetBackPosition": this.templates[index].invitation.puppetBackPosition,
            "templateShowFrontPuppet": this.templates[index].invitation.showFrontPuppet,
            "templateShowBackPuppet": this.templates[index].invitation.showBackPuppet,
            "templatePuppetFrontFrameClass": this.templates[index].invitation.puppetFrontFrameClass,
            "templatePuppetBackFrameClass": this.templates[index].invitation.puppetBackFrameClass,
            "templatePuppetFrontFrameUrl": this.templates[index].invitation.puppetFrontFrameUrl,
            "templatePuppetBackFrameUrl": this.templates[index].invitation.puppetBackFrameUrl,
            "templatePuppetFrontFramePosition": this.templates[index].invitation.puppetFrontFramePosition,
            "templatePuppetBackFramePosition": this.templates[index].invitation.puppetBackFramePosition,
            "templatePuppetFrontFrameShow": this.templates[index].invitation.puppetFrontFrameShow,
            "templatePuppetBackFrameShow": this.templates[index].invitation.puppetBackFrameShow,
            "templatePuppetFrontPoster": this.templates[index].invitation.puppetFrontPoster,
            "templatePuppetBackPoster": this.templates[index].invitation.puppetBackPoster,
            "templateCanvasFrontImage": largeurl,
            "templateCanvasBackImage": this.templates[index].invitation.canvasBackImage,
            "templateShowCardFrontBackgroundVideoUrl": this.templates[index].invitation.showCardFrontBackgroundVideoUrl,
            "templateShowCardBackBackgroundVideoUrl": this.templates[index].invitation.showCardBackBackgroundVideoUrl,
            "templatePuppetBackVideo": this.templates[index].invitation.puppetBackVideo,
            "templatePuppetFrontVideo": this.templates[index].invitation.puppetFrontVideo,
            "templateCanvasBackImageShort": this.templates[index].invitation.canvasBackImageShort,
            "templateMainbackgroundgifurl": this.templates[index].invitation.mainbackgroundgifurl,
            "templateCanvasFrontImageShort": this.templates[index].invitation.canvasFrontImageShort,
            "templateMainbackgroundvideourl": this.templates[index].invitation.mainbackgroundvideourl,
            "templateShowMainBackgroundVideo": this.templates[index].invitation.showMainBackgroundVideo,
            "templateCardBackBackgroundGifUrl": this.templates[index].invitation.cardBackBackgroundGifUrl,
            "templateCardFrontBackgroundGifUrl": this.templates[index].invitation.cardFrontBackgroundGifUrl,
            "templateCardBackBackgroundVideoUrl": this.templates[index].invitation.cardBackBackgroundVideoUrl,
            "templateCardFrontBackgroundVideoUrl": this.templates[index].invitation.cardFrontBackgroundVideoUrl,
        }
        localStorage.setItem("templateId", this.templates[index].objectId);
        this.$a.data.setVariable("invitation", iobject);
        localStorage.setItem("issearched", "yes");
        this.Apperyio.showModal("Preview", {
                componentProps: {},
                showBackdrop: true,
                backdropDismiss: true,
                cssClass: "",
                animated: true,
                keyboardClose: true
            })
            .then(modal => {
                modal.present();
                modal.onDidDismiss().then((dataReturned) => {
                    var checkit = localStorage.getItem("issearched");
                    if (checkit === "close") {
                        this.$a.closeModal(
                            /* data */
                        );
                    }
                });
            });
    }
    async doSearch(event): Promise < any > {
        if (event.keyCode === 13) {
            if (this.searchTerms === "" || this.searchTerms === null) {
                const alert = await this.alertController.create({
                    cssClass: 'my-custom-class',
                    header: 'Alert',
                    message: 'Please enter a search term.',
                    buttons: ['OK']
                });
                await alert.present();
            } else if (this.searchTerms === undefined) {
                const alert = await this.alertController.create({
                    cssClass: 'my-custom-class',
                    header: 'Alert',
                    message: 'Please enter a search term.',
                    buttons: ['OK']
                });
                await alert.present();
            } else {
                this.templates = [];
                this.showNo = "hideGrid";
                this.$a.execDataService(this, "getTemplates1"); // Change service_name to created DataSource name
            }
        }
    }
    goNext(): any {
        this.offset = this.offset + 100;
        this.$a.execDataService(this, "getTemplatesSearch2"); // Change service_name to created DataSource name
    }
    goPrev(): any {
        if (this.offset <= 10) {
            this.offset = 0;
        } else {
            this.offset = this.offset - 10;
        }
        this.$a.execDataService(this, "getTemplatesSearch1"); // Change service_name to created DataSource name
    }
    loadData($event): any {
        console.log("infinite");
        if (this.showNext === "showGridVis" && this.isOk === true) {
            console.log("get more");
            this.isOk = false;
            this.goNext();
            // this.$a.execDataService(this, "getTemplatesSearch2"); // Change service_name to created DataSource name
        } else {
            console.log("no more");
        }
        var findelement = setTimeout(() => {
            $event.target.complete();
        }, 1000);
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public alertController: AlertController) {
        this.$a = this.Apperyio;
        this.$v = this.Apperyio.vars;
        this.aioChangeDetector = this.$aio_changeDetector;
    }
    ngOnInit() {
        this.Apperyio.setThinScrollIfNeeded();
    }
    ionViewWillEnter() {
        window.currentScreen = this;
        this.pageIonViewWillEnter__j_1638();
    }
    ionViewDidEnter() {
        this.pageIonViewDidEnter__j_1638();
    }
    async pageIonViewWillEnter__j_1638(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.searchTerms = localStorage.getItem("searchTerm");
        this.queryCheck = localStorage.getItem("queryType");
        this.catName = localStorage.getItem("categoryName");
        this.Apperyio.data.getVariable("categoryName");
        this.Apperyio.data.getVariable("queryType");
        this.Apperyio.data.getVariable("searchTerm");
        this.numTimesLeft = 1;
        this.scrollingDisabled = false;
        this.showNo = "hideGrid";
        this.offset = 0;
        this.count = 100;
        this.showPrev = "hideGridVis";
        this.showNext = "hideGridVis";
        this.showBar = "hideGrid";
        this.templates = [];
        this.moreTemplates = [];
        this.recordCountNumber = 0;
        this.showNo = "hideGrid";
        this.token = localStorage.getItem("token");
        if (this.queryCheck === "search") {
            this.showTitle = "hideGrid";
            this.showSearch = "showGrid";
        } else {
            this.showTitle = "showGrid";
            this.showSearch = "hideGrid";
        }
        /* Invoke data service */
        this.invokeService_getTemplatesSearch1();
    }
    async pageIonViewDidEnter__j_1638(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.isOk = true;
    }
    async button1_copy1Click__j_1658(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Dismiss Modal */
        this.Apperyio.getController("ModalController").dismiss();
    }
    private $aio_dataServices = {
        "getTemplates1": "invokeService_getTemplates1",
        "getTemplatesSearch1": "invokeService_getTemplatesSearch1",
        "getTemplatesCount1": "invokeService_getTemplatesCount1",
        "getTemplatesSearch2": "invokeService_getTemplatesSearch2"
    };
    invokeService_getTemplates1(cb?: Function) {
        this.Apperyio.getService("getTemplates").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                params = this.$aio_mappingHelper.updateData(params, ['terms'], this.$aio_mappingHelper.getSubdata(this.searchTerms, []));
                params = this.$aio_mappingHelper.updateData(params, ['size'], this.$aio_mappingHelper.getSubdata(this.count, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'cssClass': 'previewLoader',
                        'keyboardClose': true,
                        'message': 'Please wait ...',
                        'mode': 'ios',
                        'showBackdrop': true,
                        'spinner': 'crescent',
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Mapping */
                        this.templates = this.$aio_mappingHelper.updateData(this.templates, [], this.$aio_mappingHelper.getSubdata(res, []));
                        /* Run TypeScript */
                        var tempcount = this.templates.length;
                        if (tempcount === 0) {
                            this.showNo = "showGrid";
                        } else {
                            this.showNo = "hideGrid";
                        }
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        const alert = await this.alertController.create({
                            cssClass: 'my-custom-class',
                            header: 'Alert',
                            message: 'Please check your network connection or try again later.',
                            buttons: ['OK']
                        });
                        await alert.present();
                    }
                )
            }
        );
    }
    invokeService_getTemplatesSearch1(cb?: Function) {
        this.Apperyio.getService("getTemplatesSearch").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                params = this.$aio_mappingHelper.updateData(params, ['terms'], this.$aio_mappingHelper.getSubdata(this.searchTerms, []));
                params = this.$aio_mappingHelper.updateData(params, ['off'], this.$aio_mappingHelper.getSubdata(this.offset, []));
                params = this.$aio_mappingHelper.updateData(params, ['size'], this.$aio_mappingHelper.getSubdata(this.count, []));
                /* Run TypeScript */
                //this.templates = [];
                this.showNext = "hideGridVis";
                this.showPrev = "hideGridVis";
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'cssClass': 'previewLoader',
                        'keyboardClose': true,
                        'message': 'Please wait ...',
                        'mode': 'ios',
                        'showBackdrop': true,
                        'spinner': 'crescent',
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Mapping */
                        this.templates = this.$aio_mappingHelper.updateData(this.templates, [], this.$aio_mappingHelper.getSubdata(res, []));
                        /* Run TypeScript */
                        var tempcount = this.templates.length;
                        if (tempcount === 0) {
                            this.showNo = "showGrid";
                        } else {
                            this.showNo = "hideGrid";
                        }
                        /* Invoke data service */
                        this.invokeService_getTemplatesCount1();
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        const alert = await this.alertController.create({
                            cssClass: 'my-custom-class',
                            header: 'Alert',
                            message: 'Please check your network connection or try again later.',
                            buttons: ['OK']
                        });
                        await alert.present();
                    }
                )
            }
        );
    }
    invokeService_getTemplatesCount1(cb?: Function) {
        this.Apperyio.getService("getTemplatesCount").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                params = this.$aio_mappingHelper.updateData(params, ['terms'], this.$aio_mappingHelper.getSubdata(this.searchTerms, []));
                params = this.$aio_mappingHelper.updateData(params, ['off'], this.$aio_mappingHelper.getSubdata(this.offset, []));
                params = this.$aio_mappingHelper.updateData(params, ['size'], this.$aio_mappingHelper.getSubdata(this.count, []));
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Run TypeScript */
                        this.recordCountNumber = res;
                        if (this.recordCountNumber > 100) {
                            if (this.offset > 0) {
                                this.showPrev = "showGridVis";
                                if (this.offset + 100 >= this.recordCountNumber) {
                                    this.scrollingDisabled = true;
                                    this.showNext = "hideGridVis";
                                } else {
                                    this.scrollingDisabled = false;
                                    this.showNext = "showGridVis";
                                }
                            } else {
                                this.scrollingDisabled = false;
                                this.showPrev = "hideGridVis";
                                this.showNext = "showGridVis";
                            }
                        } else {
                            this.scrollingDisabled = true;
                            this.showNext = "hideGridVis";
                            this.showPrev = "hideGridVis";
                            this.showBar = "showGrid";
                        }
                        var TIME_IN_MS = 100;
                        var hideLoadTimeout = setTimeout(() => {
                            this.$a.dismissLoading();
                        }, TIME_IN_MS);
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                    }
                )
            }
        );
    }
    invokeService_getTemplatesSearch2(cb?: Function) {
        this.Apperyio.getService("getTemplatesSearch").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                params = this.$aio_mappingHelper.updateData(params, ['terms'], this.$aio_mappingHelper.getSubdata(this.searchTerms, []));
                params = this.$aio_mappingHelper.updateData(params, ['off'], this.$aio_mappingHelper.getSubdata(this.offset, []));
                params = this.$aio_mappingHelper.updateData(params, ['size'], this.$aio_mappingHelper.getSubdata(this.count, []));
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Mapping */
                        this.moreTemplates = this.$aio_mappingHelper.updateData(this.moreTemplates, [], this.$aio_mappingHelper.getSubdata(res, []));
                        /* Run TypeScript */
                        if (this.offset + 100 >= this.recordCountNumber) {
                            this.scrollingDisabled = true;
                            this.showNext = "hideGridVis";
                        } else {
                            this.scrollingDisabled = false;
                            this.showNext = "showGridVis";
                        }
                        this.templates = this.templates.concat(this.moreTemplates);
                        var setok = setTimeout(() => {
                            this.isOk = true;
                        }, 1500);
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    (err: any) => {
                        console.log(err);
                    }
                )
            }
        );
    }
}