import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    AlertController
} from '@ionic/angular';
import {
    Dialogs
} from '@ionic-native/dialogs/ngx';
import {
    $aio_empty_object
} from '../scripts/interfaces';
import {
    ViewChild
} from '@angular/core';
@Component({
    templateUrl: 'messages.html',
    selector: 'page-messages',
    styleUrls: ['messages.css', 'messages.scss']
})
export class messages {
    public title: string;
    public guests: any;
    public attending: any;
    public notAttending: any;
    public maybeAttending: any;
    public notResponded: any;
    public showAttending: string;
    public showNot: string;
    public showMaybe: string;
    public showNotResponded: string;
    public token: string;
    public eventId: string;
    public sCount: string;
    public acount: number;
    public ncount: number;
    public mcount: number;
    public rcount: number;
    public messages: any;
    publicMessages: boolean;
    public messageId: string;
    public hostMessage: string;
    public showCreate: string;
    public createPublic: boolean;
    public destinationType: string;
    public replyButton: string;
    public buttonText: string;
    public replyMessage: string;
    public isload: boolean;
    public $a: ApperyioHelperService;
    public $v: {
        [name: string]: any
    };
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    @ViewChild('_aio_content') _aio_content;
    public mappingData: any = {
        "j_485__visible": false,
    };
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    async deleteCheck(index): Promise < any > {
        this.messageId = this.messages[index].objectId;
        this.dialogs.confirm(
            'Are you sure you would like to delete this message?', // message         // callback to invoke with index of button pressed
            'Confirm', // title
            ['No', 'Yes'] // buttonLabels
        )
        .then(buttonIndex => {
            if (buttonIndex === 2) {
                this.Apperyio.execDataService(this, "deleteMessage1"); // Change service_name to created DataSource name
            }
        })
        .catch(e => console.log('Error displaying dialog', e));
    }
    changeEvent(): any {
        if (this.isload === true) {
            this.Apperyio.execDataService(this, "updateEventMessages1"); // Change service_name to created DataSource name
        }
    }
    createMessage(): any {
        if (this.showCreate === "hideGrid") {
            this.buttonText = "Close";
            this.showCreate = "showGrid";
        } else {
            this.showCreate = "hideGrid";
            this.buttonText = "Create";
        }
    }
    async checkHostMessage(): Promise < any > {
        if (this.hostMessage === "" || this.hostMessage === null) {
            const myalert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Error',
                mode: 'ios',
                message: 'Please enter a message before proceeding.',
                buttons: ['OK']
            });
            await myalert.present();
        } else if (this.hostMessage === undefined) {
            const myalert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Error',
                mode: 'ios',
                message: 'Please enter a message before proceeding.',
                buttons: ['OK']
            });
            await myalert.present();
        } else {
            this.Apperyio.execDataService(this, "hostMessage1"); // Change service_name to created DataSource name
        }
    }
    replyOpen(index): any {
        var creply = this.messages[index].showReply;
        if (creply === "showGrid") {
            this.messages[index].showReply = "hideGrid";
        } else {
            this.messages[index].showReply = "showGrid";
        }
    }
    async sendReply(index): Promise < any > {
        this.messageId = this.messages[index].objectId;
        this.replyMessage = this.messages[index].reply;
        if (this.replyMessage === "" || this.replyMessage === null) {
            const myalert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Error',
                mode: 'ios',
                message: 'Please enter a message before proceeding.',
                buttons: ['OK']
            });
            await myalert.present();
        } else if (this.replyMessage === undefined) {
            const myalert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Error',
                mode: 'ios',
                message: 'Please enter a message before proceeding.',
                buttons: ['OK']
            });
            await myalert.present();
        } else {
            this.Apperyio.execDataService(this, "hostReply1"); // Change service_name to created DataSource name
        }
    }
    async deleteReply(index): Promise < any > {
        this.messageId = this.messages[index].objectId;
        const myalert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Confirm',
            message: 'Are you sure you would like to delete this reply?',
            mode: 'ios',
            buttons: [{
                    text: 'Cancel',
                    role: 'close',
                    handler: () => {}
                },
                {
                    text: 'Yes',
                    handler: () => {
                        this.Apperyio.execDataService(this, "deleteReply1"); // Change service_name to created DataSource name
                    }
                }
            ]
        });
        await myalert.present();
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public alertController: AlertController, public dialogs: Dialogs) {
        this.$a = this.Apperyio;
        this.$v = this.Apperyio.vars;
        this.aioChangeDetector = this.$aio_changeDetector;
    }
    ngOnInit() {
        this.Apperyio.setThinScrollIfNeeded();
    }
    ionViewWillEnter() {
        window.currentScreen = this;
        this.pageIonViewWillEnter__j_477();
    }
    async pageIonViewWillEnter__j_477(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.title = localStorage.getItem("guestType");
        this.isload = false;
        this.showCreate = "hideGrid";
        this.replyButton = "hideGrid";
        this.buttonText = "Create";
        this.createPublic = true;
        this.destinationType = "all";
        this.token = localStorage.getItem("token");
        this.eventId = localStorage.getItem("eventId");
        var isSeen = localStorage.getItem("publicMessages");
        if (isSeen === "true") {
            this.publicMessages = true;
        } else {
            this.publicMessages = false;
        }
        this.isload = true;
        this.messages = [];
        this.attending = [];
        this.notAttending = [];
        this.maybeAttending = [];
        this.notResponded = [];
        this.showAttending = "hideGrid";
        this.showNot = "hideGrid";
        this.showMaybe = "hideGrid";
        this.showNotResponded = "hideGrid";
        this.Apperyio.execDataService(this, "getEventMessages1"); // Change service_name to created DataSource name
    }
    async button1Click__j_511(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Dismiss Modal */
        this.Apperyio.getController("ModalController").dismiss();
    }
    private $aio_dataServices = {
        "getEventMessages1": "invokeService_getEventMessages1",
        "updateEventMessages1": "invokeService_updateEventMessages1",
        "deleteMessage1": "invokeService_deleteMessage1",
        "hostMessage1": "invokeService_hostMessage1",
        "hostReply1": "invokeService_hostReply1",
        "deleteReply1": "invokeService_deleteReply1"
    };
    invokeService_getEventMessages1(cb?: Function) {
        this.Apperyio.getService("getEventMessages").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                params = this.$aio_mappingHelper.updateData(params, ['id'], this.$aio_mappingHelper.getSubdata(this.eventId, []));
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Run TypeScript */
                        this.messages = res;

                        function dateFormater(date) {
                            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                            var day = date.getDate();
                            // get month from 0 to 11
                            var month = date.getMonth();
                            // conver month digit to month name
                            month = months[month];
                            var year = date.getFullYear();
                            // show date in two digits
                            if (day < 10) {
                                day = '0' + day;
                            }
                            // now we have day, month and year
                            // arrange them in the format we want
                            return month + ' ' + day + ', ' + year;
                        }
                        if (this.messages.length < 1) {
                            this.showAttending = "showGrid";
                        } else {
                            this.showAttending = "hideGrid";
                            for (let i = 0; i < this.messages.length; i++) {
                                this.messages[i].created = dateFormater(new Date(this.messages[i].created));
                                if (this.messages[i].replyDate > 0) {
                                    this.messages[i].replyDate = dateFormater(new Date(this.messages[i].replyDate));
                                }
                                var namestring = this.messages[i].name;
                                if (namestring.indexOf('Event Host:') !== -1) {
                                    this.messages[i].replyButton = "hideGrid";
                                } else {
                                    this.messages[i].replyButton = "showGrid";
                                }
                            }
                        }
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Run TypeScript */
                        var mye = JSON.stringify(err);
                        const myalert = await this.alertController.create({
                            cssClass: 'my-custom-class',
                            header: 'Error',
                            message: 'There was an error. Please try again later.',
                            buttons: ['OK']
                        });
                        await myalert.present();
                    }
                )
            }
        );
    }
    invokeService_updateEventMessages1(cb?: Function) {
        this.Apperyio.getService("updateEventMessages").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                data = this.$aio_mappingHelper.updateData(data, ["publicMessages"], this.$aio_mappingHelper.getSubdata(this.publicMessages, []));
                params = this.$aio_mappingHelper.updateData(params, ['id'], this.$aio_mappingHelper.getSubdata(this.eventId, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'keyboardClose': true,
                        'message': 'please wait',
                        'showBackdrop': true,
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        localStorage.setItem("publicMessages", this.publicMessages.toString());
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        var mye = JSON.stringify(err);
                        const myalert = await this.alertController.create({
                            cssClass: 'my-custom-class',
                            header: 'Error',
                            message: 'There was an error. Please try again later.',
                            buttons: ['OK']
                        });
                        await myalert.present();
                    }
                )
            }
        );
    }
    invokeService_deleteMessage1(cb?: Function) {
        this.Apperyio.getService("deleteMessage").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                params = this.$aio_mappingHelper.updateData(params, ['id'], this.$aio_mappingHelper.getSubdata(this.messageId, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'keyboardClose': true,
                        'message': 'please wait',
                        'showBackdrop': true,
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        this.messages = [];
                        this.Apperyio.execDataService(this, "getEventMessages1"); // Change service_name to created DataSource name
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        var mye = JSON.stringify(err);
                        const myalert = await this.alertController.create({
                            cssClass: 'my-custom-class',
                            header: 'Error',
                            message: 'There was an error. Please try again later.',
                            buttons: ['OK']
                        });
                        await myalert.present();
                    }
                )
            }
        );
    }
    invokeService_hostMessage1(cb?: Function) {
        this.Apperyio.getService("hostMessage").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                data = this.$aio_mappingHelper.updateData(data, ["message"], this.$aio_mappingHelper.getSubdata(this.hostMessage, []));
                data = this.$aio_mappingHelper.updateData(data, ["eventId"], this.$aio_mappingHelper.getSubdata(this.eventId, []));
                data = this.$aio_mappingHelper.updateData(data, ["guests"], this.$aio_mappingHelper.getSubdata(this.destinationType, []));
                data = this.$aio_mappingHelper.updateData(data, ["isVisible"], this.$aio_mappingHelper.getSubdata(this.createPublic, []));
                data = this.$aio_mappingHelper.updateData(data, ["replyButton"], this.$aio_mappingHelper.getSubdata(this.replyButton, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'keyboardClose': true,
                        'message': 'Please wait ...',
                        'showBackdrop': true,
                        'spinner': 'crescent',
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        const myalert = await this.alertController.create({
                            cssClass: 'my-custom-class',
                            header: 'Success',
                            mode: 'ios',
                            message: 'Your message was sent.',
                            buttons: ['OK']
                        });
                        await myalert.present();
                        this.$a.closeModal(
                            /* data */
                        );
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        const myalert = await this.alertController.create({
                            cssClass: 'my-custom-class',
                            header: 'Error',
                            mode: 'ios',
                            message: 'There was an error. Please try again later.',
                            buttons: ['OK']
                        });
                        await myalert.present();
                    }
                )
            }
        );
    }
    invokeService_hostReply1(cb?: Function) {
        this.Apperyio.getService("hostReply").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                data = this.$aio_mappingHelper.updateData(data, ["eventId"], this.$aio_mappingHelper.getSubdata(this.eventId, []));
                data = this.$aio_mappingHelper.updateData(data, ["message"], this.$aio_mappingHelper.getSubdata(this.replyMessage, []));
                data = this.$aio_mappingHelper.updateData(data, ["messageId"], this.$aio_mappingHelper.getSubdata(this.messageId, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'keyboardClose': true,
                        'message': 'Please wait ...',
                        'showBackdrop': true,
                        'spinner': 'crescent',
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        this.messages = [];
                        this.showCreate = "hideGrid";
                        this.buttonText = "Create";
                        this.Apperyio.execDataService(this, "getEventMessages1"); // Change service_name to created DataSource name
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        const myalert = await this.alertController.create({
                            cssClass: 'my-custom-class',
                            header: 'Error',
                            mode: 'ios',
                            message: 'There was an error. Please try again later.',
                            buttons: ['OK']
                        });
                        await myalert.present();
                    }
                )
            }
        );
    }
    invokeService_deleteReply1(cb?: Function) {
        this.Apperyio.getService("deleteReply").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                data = this.$aio_mappingHelper.updateData(data, ["messageId"], this.$aio_mappingHelper.getSubdata(this.messageId, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'keyboardClose': true,
                        'message': 'Please wait ...',
                        'showBackdrop': true,
                        'spinner': 'crescent',
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        this.messages = [];
                        this.showCreate = "hideGrid";
                        this.buttonText = "Create";
                        this.Apperyio.execDataService(this, "getEventMessages1"); // Change service_name to created DataSource name
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        const myalert = await this.alertController.create({
                            cssClass: 'my-custom-class',
                            header: 'Error',
                            mode: 'ios',
                            message: 'There was an error. Please try again later.',
                            buttons: ['OK']
                        });
                        await myalert.present();
                    }
                )
            }
        );
    }
}