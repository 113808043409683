import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    AlertController
} from '@ionic/angular';
import {
    NavController
} from '@ionic/angular';
import {
    Router
} from '@angular/router';
import {
    Purchases
} from '@ionic-native/purchases/ngx';
import {
    $aio_empty_object
} from '../scripts/interfaces';
import {
    ViewChild
} from '@angular/core';
@Component({
    templateUrl: 'UpgradePopup.html',
    selector: 'page-upgrade-popup',
    styleUrls: ['UpgradePopup.css', 'UpgradePopup.scss']
})
export class UpgradePopup {
    public maindescription: string;
    public products: any;
    public p1Title: string;
    public p1Price: string;
    public p1Id: string;
    public p1Sub: string;
    public p2Title: string;
    public p2Price: string;
    public p2Id: string;
    public p2Sub: string;
    public package1: any;
    public package2: any;
    public $a: ApperyioHelperService;
    public $v: {
        [name: string]: any
    };
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    @ViewChild('_aio_content') _aio_content;
    public mappingData: any = {
        "j_11__visible": false,
    };
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    goB(): any {
        this.Apperyio.getController("ModalController").dismiss(
            /* data */
        );
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public purchases: Purchases, public alertController: AlertController, public navController: NavController, public router: Router) {
        this.$a = this.Apperyio;
        this.$v = this.Apperyio.vars;
        this.aioChangeDetector = this.$aio_changeDetector;
    }
    ngOnInit() {
        this.Apperyio.setThinScrollIfNeeded();
    }
    ionViewWillEnter() {
        window.currentScreen = this;
        this.pageIonViewWillEnter__j_0();
    }
    async pageIonViewWillEnter__j_0(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        let offerings: any = {};
        this.package1 = {};
        this.package2 = {};
        try {
            offerings = await this.purchases.getOfferings();
            console.log(offerings);
            if (offerings.current !== null && offerings.current.availablePackages.length !== 0) {
                this.maindescription = offerings.current.serverDescription;
                this.products = offerings.current.availablePackages;
                for (var i = 0, l = offerings.current.availablePackages.length; i < l; i++) {
                    var p1type = offerings.current.availablePackages[i].packageType;
                    if (p1type === "MONTHLY") {
                        this.package1 = offerings.current.availablePackages[i];
                        this.p1Id = offerings.current.availablePackages[i].product.identifier;
                        this.p1Price = offerings.current.availablePackages[i].product.price_string;
                        this.p1Sub = "Save 50%";
                        this.p1Title = "Monthly";
                    } else {
                        this.package2 = offerings.current.availablePackages[i];
                        this.p2Id = offerings.current.availablePackages[i].product.identifier;
                        this.p2Title = offerings.current.availablePackages[i].product.identifier;
                        this.p2Price = offerings.current.availablePackages[i].product.price_string;
                        this.p2Sub = "Save 50%";
                        this.p2Title = "Annually";
                    }
                }
            }
        } catch (e) {
        }
    }
    async card1_copyClick__j_12(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.Apperyio.getController("LoadingController").create({
            message: 'Please wait...',
            spinner: 'crescent'
        }).then(loading => loading.present());
        let mypurchase: any = {};
        try {
            mypurchase = await this.purchases.purchaseProduct(this.p1Id);
            var myjson = JSON.stringify(mypurchase);
            this.Apperyio.getController("LoadingController").dismiss();
            if (mypurchase.customerInfo.entitlements.active.premium !== "undefined") {
                // Unlock that great "pro" content
                //this.navController.back();
                var lastPage = localStorage.getItem("latest");
                if (lastPage === "home") {
                    this.navController.navigateRoot('/tabs/Home');
                } else if (lastPage === "account") {
                    this.navController.navigateRoot('/tabs/Account');
                } else if (lastPage === "audio") {
                    this.navController.navigateRoot('/tabs/Audio');
                } else {
                    this.navController.navigateRoot('/tabs/Home');
                }
            } else {
                this.alertController.create({
                    header: 'Alert',
                    message: 'Your purchase could not be completed.',
                    buttons: ['OK']
                }).then(res => {
                    res.present();
                });
            }
            //alert(myjson);
        } catch (e) {
            var mye = JSON.stringify(e);
            this.Apperyio.getController("LoadingController").dismiss();
            var lastPage = localStorage.getItem("latest");
            if (lastPage === "home") {
                this.navController.navigateRoot('/tabs/Home');
            } else if (lastPage === "account") {
                this.navController.navigateRoot('/tabs/Account');
            } else if (lastPage === "audio") {
                this.navController.navigateRoot('/tabs/Audio');
            } else {
                this.navController.navigateRoot('/tabs/Home');
            }
        }
    }
    async card1_copy1Click__j_21(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.Apperyio.getController("LoadingController").create({
            message: 'Please wait...',
            spinner: 'crescent'
        }).then(loading => loading.present());
        let mypurchase: any = {};
        try {
            mypurchase = await this.purchases.purchaseProduct(this.p2Id);
            var myjson = JSON.stringify(mypurchase);
            this.Apperyio.getController("LoadingController").dismiss();
            if (mypurchase.customerInfo.entitlements.active.premium !== "undefined") {
                // Unlock that great "pro" content
                //this.navController.back();
                this.Apperyio.getController("ModalController").dismiss(
                    /* data */
                );
            } else {
                this.alertController.create({
                    header: 'Alert',
                    message: 'Your purchase could not be completed.',
                    buttons: ['OK']
                }).then(res => {
                    res.present();
                });
                this.Apperyio.getController("ModalController").dismiss(
                    /* data */
                );
            }
            //alert(myjson);
        } catch (e) {
            var mye = JSON.stringify(e);
            this.Apperyio.getController("LoadingController").dismiss();
            this.Apperyio.getController("ModalController").dismiss(
                /* data */
            );
        }
    }
    async text6Click__j_31(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.Apperyio.getController("LoadingController").create({
            message: 'Please wait...',
            spinner: 'crescent',
            duration: 2000
        }).then(loading => loading.present());
        let myrestore: any = {};
        try {
            myrestore = await this.purchases.restoreTransactions();
            var myjson = JSON.stringify(myrestore);
            this.Apperyio.getController("LoadingController").dismiss();
            //alert(myjson);
            if (myrestore.purchaserInfo.entitlements.active.isActive !== false) {
                // Unlock that great "pro" content
                var lastPage = localStorage.getItem("latest");
                if (lastPage === "home") {
                    this.navController.navigateRoot('/tabs/Home');
                } else if (lastPage === "account") {
                    this.navController.navigateRoot('/tabs/Account');
                } else if (lastPage === "scenes") {
                    this.navController.back();
                } else if (lastPage === "audio") {
                    //this.navController.back();
                    this.navController.navigateRoot('/audio');
                } else {
                    this.navController.navigateRoot('/tabs/Home');
                }
            } else {
                this.alertController.create({
                    header: 'Alert',
                    message: 'There was an error. Please try again later.',
                    buttons: ['OK']
                }).then(res => {
                    res.present();
                });
            }
        } catch (e) {
            var mye = JSON.stringify(e);
            if (mye.indexOf('trying to validate the receipt') !== -1) {
                this.Apperyio.getController("LoadingController").dismiss();
                var lastPage = localStorage.getItem("latest");
                if (lastPage === "home") {
                    this.navController.navigateRoot('/tabs/Home');
                } else if (lastPage === "account") {
                    this.navController.navigateRoot('/tabs/Account');
                } else if (lastPage === "scenes") {
                    this.navController.back();
                } else if (lastPage === "audio") {
                    this.navController.back();
                } else {
                    this.navController.navigateRoot('/tabs/Home');
                }
                //all good
            } else {
                this.Apperyio.getController("LoadingController").dismiss();
                this.alertController.create({
                    header: 'Alert',
                    message: 'There was an error. Please try again later.',
                    buttons: ['OK']
                }).then(res => {
                    res.present();
                });
            }
        }
    }
}