import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    NavController
} from '@ionic/angular';
import {
    InAppBrowser
} from '@ionic-native/in-app-browser/ngx';
import {
    $aio_empty_object
} from '../scripts/interfaces';
import {
    ViewChild
} from '@angular/core';
@Component({
    templateUrl: 'Preview.html',
    selector: 'page-preview',
    styleUrls: ['Preview.css', 'Preview.scss']
})
export class Preview {
    public currentVideo: string;
    public currentPoster: string;
    public mytheme: string;
    public showPlay: string;
    public showPause: string;
    public footerClass: string;
    public token: string;
    public templateId: string;
    public title: any;
    public template: any;
    public templateName: string;
    public templateCost: number;
    public templateCostString: string;
    public templateBackground: string;
    public templateFrontBackground: string;
    public templateBackBackground: string;
    public templateEnvelope: string;
    public templatePuppetFrontPosition: string;
    public templatePuppetBackPosition: string;
    public templateShowFrontPuppet: string;
    public templateShowBackPuppet: string;
    public templatePuppetFrontFrameClass: string;
    public templatePuppetBackFrameClass: string;
    public templatePuppetFrontFrameUrl: string;
    public templatePuppetBackFrameUrl: string;
    public templatePuppetFrontFramePosition: string;
    public templatePuppetBackFramePosition: string;
    public templatePuppetFrontFrameShow: string;
    public templatePuppetBackFrameShow: string;
    public templatePuppetFrontPoster: string;
    public templatePuppetBackPoster: string;
    public templateCanvasFrontImage: string;
    public templateCanvasBackImage: string;
    public playclassfront: string;
    public squareclassfront: string;
    public pauseclassfront: string;
    public pausesquareclassfront: string;
    public showPlay2: string;
    public showPause2: string;
    public pauseclassfront2: string;
    public playclassfront2: string;
    public pausesquareclassfront2: string;
    public squareclassfront2: string;
    public templateShowCardFrontBackgroundVideoUrl: string;
    public templateShowCardBackBackgroundVideoUrl: string;
    public templatePuppetBackVideo: string;
    public templatePuppetFrontVideo: string;
    public templateCanvasBackImageShort: string;
    public templateMainbackgroundgifurl: string;
    public templateCanvasFrontImageShort: string;
    public templateMainbackgroundvideourl: string;
    public templateShowMainBackgroundVideo: string;
    public templateCardBackBackgroundGifUrl: string;
    public templateCardFrontBackgroundGifUrl: string;
    public templateCardBackBackgroundVideoUrl: string;
    public templateCardFrontBackgroundVideoUrl: string;
    public showFlipper: string;
    public showLoad: string;
    public eventId: string;
    public templateLiner: string;
    public $a: ApperyioHelperService;
    public $v: {
        [name: string]: any
    };
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    @ViewChild('_aio_content') _aio_content;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    playVideo(): any {
        this.showPlay = "hideGrid";
        this.showPause = "showGrid";
        const x = document.getElementById('example') as HTMLVideoElement | null;
        x.play();
        x.addEventListener('ended', (e: any) => {
            this.showPlay = "showGrid";
            this.showPause = "hideGrid";
        });
    }
    pauseVideo(): any {
        this.showPlay = "showGrid";
        this.showPause = "hideGrid";
        const x = document.getElementById('example') as HTMLVideoElement | null;
        x.pause();
    }
    goRecord(): any {
        localStorage.setItem("goaudio", "yes");
        this.Apperyio.getController("ModalController").dismiss(
            /* data */
        );
    }
    goCanvas(): any {
        localStorage.setItem("showprogress", "yes");
        this.$a.execDataService(this, "getPuppets2"); // Change service_name to created DataSource name
    }
    pauseVideoPreview(): any {
        this.showPlay = "showGrid";
        this.showPause = "hideGrid";
        const x = document.getElementById('largepreview') as HTMLVideoElement | null;
        x.pause();
    }
    playVideoPreview(): any {
        this.showPlay = "hideGrid";
        this.showPause = "showGrid";
        const x = document.getElementById('largepreview') as HTMLVideoElement | null;
        x.play();
        x.addEventListener('ended', (e: any) => {
            this.showPlay = "showGrid";
            this.showPause = "hideGrid";
        });
    }
    playVideoPreview2(): any {
        this.showPlay2 = "hideGrid";
        this.showPause2 = "showGrid";
        const x = document.getElementById('large2preview') as HTMLVideoElement | null;
        x.play();
        x.addEventListener('ended', (e: any) => {
            this.showPlay2 = "showGrid";
            this.showPause2 = "hideGrid";
        });
    }
    pauseVideoPreview2(): any {
        this.showPlay2 = "showGrid";
        this.showPause2 = "hideGrid";
        const x = document.getElementById('large2preview') as HTMLVideoElement | null;
        x.pause();
    }
    goPreview(): any {
        var previewId = "https://www.puppetpartyapp.com/open?type=template&id=" + this.templateId;
        this.iab.create(previewId, '_blank', 'toolbarposition=top,allowInlineMediaPlayback=yes,toolbarcolor=#ffffff,closebuttoncolor=#001e42,hidenavigationbuttons=yes,hidespinner=yes');
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public navCtrl: NavController, public iab: InAppBrowser) {
        this.$a = this.Apperyio;
        this.$v = this.Apperyio.vars;
        this.aioChangeDetector = this.$aio_changeDetector;
    }
    ngOnInit() {
        this.Apperyio.setThinScrollIfNeeded();
    }
    ionViewWillEnter() {
        window.currentScreen = this;
        this.pageIonViewWillEnter__j_1734();
    }
    ionViewDidEnter() {
        this.pageIonViewDidEnter__j_1734();
    }
    async pageIonViewWillEnter__j_1734(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        //animate__animated animate__pulse animate__infinite
        this.token = localStorage.getItem("token");
        localStorage.setItem("load", "normal");
        this.eventId = localStorage.getItem("templateEventId");
        this.templateId = localStorage.getItem("templateId");
        var footerbar = document.getElementById("footerbar");
        var iobject = this.$a.data.getVariable("invitation");
        console.log(iobject);
        if (iobject.hasBack === true) {
            this.showFlipper = "showGrid";
        } else {
            this.showFlipper = "hideGrid";
        }
        this.showPlay = "hideGrid";
        this.showPause = "hideGrid";
        this.showPlay2 = "hideGrid";
        this.showPause2 = "hideGrid";
        this.showLoad = "showGrid";
        this.templateCost = iobject.templateCost;
        if (this.templateCost === 0) {
            this.templateCostString = "Free Template";
        } else {
            this.templateCostString = "$" + this.templateCost.toString() + " - Premium Template";
        }
        localStorage.setItem("templateCost", this.templateCost.toString());
        this.templateName = iobject.templateName;
        this.templateBackground = iobject.templateBackground;
        this.templateLiner = iobject.envelopeLiner;
        this.templateFrontBackground = iobject.templateFrontBackground;
        this.templateBackBackground = iobject.templateBackBackground;
        this.templateEnvelope = iobject.envelopeClass;
        this.templatePuppetFrontPosition = iobject.templatePuppetFrontPosition;
        this.templatePuppetBackPosition = iobject.templatePuppetBackPosition;
        this.templateShowFrontPuppet = iobject.templateShowFrontPuppet;
        this.templateShowBackPuppet = iobject.templateShowBackPuppet;
        this.templatePuppetFrontFrameClass = iobject.templatePuppetFrontFrameClass;
        this.templatePuppetBackFrameClass = iobject.templatePuppetBackFrameClass;
        this.templatePuppetFrontFrameUrl = iobject.templatePuppetFrontFrameUrl;
        this.templatePuppetBackFrameUrl = iobject.templatePuppetBackFrameUrl;
        this.templatePuppetFrontFramePosition = iobject.templatePuppetFrontFramePosition;
        this.templatePuppetBackFramePosition = iobject.templatePuppetBackFramePosition;
        this.templatePuppetFrontFrameShow = iobject.templatePuppetFrontFrameShow;
        this.templatePuppetBackFrameShow = iobject.templatePuppetBackFrameShow;
        this.templatePuppetFrontPoster = iobject.templatePuppetFrontPoster;
        this.templatePuppetBackPoster = iobject.templatePuppetBackPoster;
        this.templateCanvasFrontImage = iobject.templateCanvasFrontImage;
        this.templateCanvasBackImage = iobject.templateCanvasBackImage;
        this.templateShowCardFrontBackgroundVideoUrl = iobject.templateShowCardFrontBackgroundVideoUrl;
        this.templateShowCardBackBackgroundVideoUrl = iobject.templateShowCardBackBackgroundVideoUrl;
        this.templatePuppetBackVideo = iobject.templatePuppetBackVideo;
        this.templatePuppetFrontVideo = iobject.templatePuppetFrontVideo;
        this.templateCanvasBackImageShort = iobject.templateCanvasBackImageShort;
        this.templateMainbackgroundgifurl = iobject.templateMainbackgroundgifurl;
        this.templateCanvasFrontImageShort = iobject.templateCanvasFrontImageShort;
        this.templateMainbackgroundvideourl = iobject.templateMainbackgroundvideourl;
        this.templateShowMainBackgroundVideo = iobject.templateShowMainBackgroundVideo;
        this.templateCardBackBackgroundGifUrl = iobject.templateCardBackBackgroundGifUrl;
        this.templateCardFrontBackgroundGifUrl = iobject.templateCardFrontBackgroundGifUrl;
        this.templateCardBackBackgroundVideoUrl = iobject.templateCardBackBackgroundVideoUrl;
        this.templateCardFrontBackgroundVideoUrl = iobject.templateCardFrontBackgroundVideoUrl;
        if (this.templateShowMainBackgroundVideo === "showGrid") {
            this.templateBackground = "";
            var backplay = document.getElementById('bgvidmainpreview') as HTMLVideoElement | null;
            backplay.load();
            backplay.play();
        } else {
            //this.mainbackground = this.invitation.mainBackgroundUrl;
        }
        if (this.templateShowFrontPuppet === "showGrid") {
            const x = document.getElementById('largepreview') as HTMLVideoElement | null;
            const xgrid = document.getElementById('largegridpreview');
            x.load();
            if (this.templatePuppetFrontPosition === "vclass") {
                this.playclassfront = "playIcon";
                this.squareclassfront = "squareIcon";
                if (this.templatePuppetFrontFrameShow === "showGrid") {
                    this.pauseclassfront = "pauseIconFrame";
                    this.pausesquareclassfront = "pauseIconSquareFrame";
                } else {
                    this.pauseclassfront = "pauseIcon";
                    this.pausesquareclassfront = "pauseIconSquare";
                }
            } else {
                this.playclassfront = "playIconBottom";
                this.squareclassfront = "squareIconBottom";
                if (this.templatePuppetFrontFrameShow === "showGrid") {
                    this.pauseclassfront = "pauseIconBottomFrame";
                    this.pausesquareclassfront = "pauseIconSquareBottomFrame";
                } else {
                    this.pauseclassfront = "pauseIcon";
                    this.pausesquareclassfront = "pauseIconSquare";
                }
            }
            this.showPlay = "showGrid";
            this.showPause = "hideGrid";
            xgrid.classList.remove("hideGrid");
            xgrid.classList.add("showGrid");
            this.templateShowFrontPuppet = "showGrid";
        }
        if (this.templateShowBackPuppet === "showGrid") {
            const x = document.getElementById('large2preview') as HTMLVideoElement | null;
            const xgrid = document.getElementById('largegrid2preview');
            x.load();
            if (this.templatePuppetBackPosition === "vclass") {
                this.playclassfront2 = "playIcon";
                this.squareclassfront2 = "squareIcon";
                if (this.templatePuppetBackFrameShow === "showGrid") {
                    this.pauseclassfront2 = "pauseIconFrame";
                    this.pausesquareclassfront2 = "pauseIconSquareFrame";
                } else {
                    this.pauseclassfront2 = "pauseIcon";
                    this.pausesquareclassfront2 = "pauseIconSquare";
                }
            } else {
                this.playclassfront2 = "playIconBottom";
                this.squareclassfront2 = "squareIconBottom";
                if (this.templatePuppetBackFrameShow === "showGrid") {
                    this.pauseclassfront2 = "pauseIconBottomFrame";
                    this.pausesquareclassfront2 = "pauseIconSquareBottomFrame";
                } else {
                    this.pauseclassfront2 = "pauseIconBottom";
                    this.pausesquareclassfront2 = "pauseIconSquareBottom";
                }
            }
            this.showPlay2 = "showGrid";
            this.showPause2 = "hideGrid";
            xgrid.classList.remove("hideGrid");
            xgrid.classList.add("showGrid");
            this.templatePuppetBackFrameShow = "showGrid";
        }
        if (this.templateShowCardFrontBackgroundVideoUrl === "showGrid") {
            var backplayfront = document.getElementById('bgvidfrontpreview') as HTMLVideoElement | null;
            backplayfront.load();
            backplayfront.play();
        } else {
            // this.backstylefront = this.invitation.cardFrontBackground;
        }
        if (this.templateShowCardBackBackgroundVideoUrl === "showGrid") {
            //this.backstylefront2 = "";  
            var backplayback = document.getElementById('bgvidbackpreview') as HTMLVideoElement | null;
            backplayback.load();
            backplayback.play();
        } else {
            //this.backstylefront2 = this.invitation.cardBackBackground;   
        }
        let TIME_IN_MS = 3000;
        let hideTimeout = setTimeout(() => {
            this.showLoad = "hidden";
        }, TIME_IN_MS);
    }
    async pageIonViewDidEnter__j_1734(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        document.getElementById("flipitpreview").addEventListener("click", params => {
            document.getElementById('canvasmainerpreview').classList.toggle('do-flip');
        });
    }
    async button1_copy2Click__j_1781(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Dismiss Modal */
        this.Apperyio.getController("ModalController").dismiss();
    }
    async gridrow3Click__j_1792(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Present Modal */
        this.Apperyio.showModal("Pricing", {
            'animated': true,
            'backdropDismiss': false,
            'keyboardClose': true,
            'showBackdrop': true,
        }).then(modal => {
            modal.present();
        });
    }
    private $aio_dataServices = {
        "getPuppets2": "invokeService_getPuppets2"
    };
    invokeService_getPuppets2(cb?: Function) {
        this.Apperyio.getService("getPuppets").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'cssClass': 'previewLoader',
                        'keyboardClose': true,
                        'message': 'Please wait ...',
                        'mode': 'ios',
                        'showBackdrop': true,
                        'spinner': 'crescent',
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        localStorage.setItem("inviteId", "new");
                        localStorage.setItem("eventUpdate", "no");
                        //this.Apperyio.navigation.forward("Canvas"/*,[route, params, here], {NavigationExtras and AnimationOptions} */);
                        this.navCtrl.navigateRoot('/canvas');
                        var checkit = localStorage.getItem("issearched");
                        if (checkit === "yes") {
                            localStorage.setItem("issearched", "close");
                        }
                        this.Apperyio.getController("ModalController").dismiss(
                            /* data */
                        );
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        this.Apperyio.showModal("Login", {
                                componentProps: {},
                                showBackdrop: true,
                                backdropDismiss: true,
                                cssClass: "",
                                animated: false,
                                keyboardClose: true
                            })
                            .then(modal => {
                                modal.present();
                                modal.onDidDismiss().then((dataReturned) => {
                                    var isauth = localStorage.getItem("auth");
                                    if (isauth === "granted") {
                                        this.navCtrl.navigateRoot('/canvas');
                                        var checkit = localStorage.getItem("issearched");
                                        if (checkit === "yes") {
                                            localStorage.setItem("issearched", "close");
                                        }
                                        this.$a.closeModal(
                                            /* data */
                                        );
                                    }
                                });
                            });
                    }
                )
            }
        );
    }
}